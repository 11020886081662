<template>
  <div style="overflow-x: hidden;">
    <b-row
      style="width: 100%; overflow: hidden;"
      align-h="between"
      class="w-100"
    >
      <b-button-group style="margin-left: 0px; margin-bottom: 5px;">
        <b-button
          v-b-tooltip.hover
          size="sm"
          :disabled="!loaded_amounts"
          title="Total charge Sheet Amount"
          :pressed="true"
          variant="dark"
        > Total Amount: <strong> KSH {{ formatPrice(total_charge_sheet_bill)
        }}</strong>
        </b-button>
        <b-button
          v-b-tooltip.hover
          size="sm"
          :disabled="!loaded_amounts"
          title="Total billed charge sheets amount"
          :pressed="true"
          variant="light"
        > Total Billed Amount:<strong> KSH {{
          formatPrice(total_charge_sheet_bill_submitted) }} </strong> </b-button>

      </b-button-group>

      <b-button-group >
        <b-button
          v-b-modal.sidebar-13
          size="sm"
          variant="outline-primary"
        >
          Filters
        </b-button>
        <b-button
          v-if="!selected.length"
          size="sm"
          style="margin-right:1px; margin-left: 0px;"
          :disabled="is_loading"
          variant="primary"
          @click="refresh()"
        > {{ is_loading ? 'Please Wait ...'
          : 'Refresh' }} </b-button>
        <!-- <b-button v-if="!selected.length"   size="sm" style="margin-right:0px; margin-left: 3px;" :disabled = "Generating_Bill"  variant="warning" @click="generate_bill()">  {{Generating_Bill ? 'Generating ...' :'Generate Bill'}}  </b-button> -->
      </b-button-group>
    </b-row>
    <b-modal
      id="sidebar-13"
      size="lg"
      title="Filter Charge Sheet"
      hide-footer
      shadow
    >
      <b-row>
        <b-col>

          <b-form-select
            v-model="selected_filter"
            size="sm"
            :options="filter_options"
            class="md-6 form-control"
            @change="onChangeFilter($event)"
          />

        </b-col>
        <b-col>
          <!-- range date picker -->
          <b-form-datepicker
            v-if="selected_filter == 'Date'"
            v-model="start_date"
            size="sm"
            placeholder="Start Date"
            :min="min_start_date"
            :max="max_start_date"
            locale="en"
            @input="onChangeFilterStartDate"
          />

          <!-- single date picker -->
          <b-form-datepicker
            v-if="selected_filter == 'Date_Single'"
            v-model="single_date"
            size="sm"
            placeholder="Selected Date"
            :min="min_start_date"
            :max="max_end_date"
            locale="en"
            @input="onChangeFilterSingleDate"
          >
            <template #day-content="{ date }">
              <div :class="enabledDates.includes(date.toISOString().substr(0, 10)) ? 'highlighted-date' : ''">
                {{ date.getDate() }}
              </div>
            </template>
          </b-form-datepicker>

          <v-select
            v-if="selected_filter == 'Warehouse'"
            v-model="ware_houses_filter"
            :options="ware_houses_filter_list"
            placeholder="Select A Warehouse Option"
            :searchable="true"
            @input="onChangeWareHouse($event)"
          />

          <v-select
            v-if="selected_filter == 'Invoice'"
            v-model="invoices_filter"
            :options="invoices_filter_list"
            placeholder="Select Invoice Option"
            :searchable="true"
            @input="onChangeInvoice($event)"
          />

          <v-select
            v-if="selected_filter == 'Biller'"
            v-model="billers_filter"
            :options="billers_filter_list"
            placeholder="Select Biller Option"
            :searchable="true"
            @input="onChangeBiller($event)"
          />

          <v-select
            v-if="selected_filter == 'Admission'"
            v-model="admissions_filter"
            :options="admissions_filter_list"
            placeholder="Select Admission Period"
            :searchable="true"
            :no-options="'patient has no Prior Admissions'"
            @input="onChangeAdmissionPeriod($event)"
          />

          <v-select
            v-if="selected_filter == 'Payment_Mode'"
            v-model="payment_method_filter"
            :options="payment_method_filter_list"
            placeholder="Select Payment Option"
            :searchable="true"
            @input="onChangePayment($event)"
          />

        </b-col>
        <div
          v-if="selected_filter == 'Date'"
          class="col-auto"
          style="margin-top: 5px;"
        > <strong
          style="margin-top: 5px;"
        >
          TO </strong></div>
        <b-col>

          <b-form-datepicker
            v-if="selected_filter == 'Date'"
            v-model="end_date"
            size="sm"
            :disabled="!start_date"
            placeholder="End Date"
            today-button
            :min="min_end_date"
            :max="max_end_date"
            locale="en"
            @input="onChangeFilterEndDate"
          />
        </b-col>
      </b-row>

      <b-row style=" margin-top: 5px;">
        <b-col>
          <b-form-input
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search Charge Sheets ..."
          />
        </b-col>

        <b-col v-if="!is_loading">

          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="!is_loading"
          class="text-right"
        >
          <b-form-group
            label="Pages"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-2 mb-2">
        <b-col>
          <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet class="ma-2 pa-2">
              <b-alert
                v-model="showDismissibleAlert"
                variant="info"
                dismissible
              >
                {{ response_message }}
              </b-alert>

              <b-alert
                v-model="showDismissibleAlertSuccess"
                variant="success"
                dismissible
              >
                {{ response_message }}
              </b-alert>

              <b-alert
                v-model="showDismissibleAlertError"
                variant="danger"
                dismissible
              >
                {{ response_message }}
              </b-alert>
            </v-sheet>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-row style="margin-left : 0px;margin-right : 0px;">
      <b-table
        ref="selectableTable1"
        style="width: 100%;"
        responsive="sm"
        bordered
        stacked="sm"
        small
        striped
        :fields="headers"
        :filter="filter"
        :items="charge_sheets_items"
        :empty-text="`No items`"
        hover
        head-row-variant="primary"
        :show-empty="true"
        :select-mode="'multi'"
        selectable
        select-variant="custom-selectable"
        :busy="is_loading"
        :tbody-tr-class="rowClass"
        :per-page="perPage"
        :current-page="currentPage"
        @row-selected="onRowSelected"
      >

        <template #cell(actions)="row">
          <div class="text-center">
            <b-dropdown
              v-if="row.item.Charge_Sheet !== current_billed_row"
              id="dropdown-left"
              size="sm"
              text="Actions"
              offset="25"
              variant="outline-primary"
            >
              <b-dropdown-item
                size="sm"
                @click="row.toggleDetails"
              > {{ row.detailsShowing ? 'Less' :
                'More' }} </b-dropdown-item>
              <b-dropdown-item
                v-if="!row.item.stock_deducted && row.item.docstatus == 1 && !row.item.completed && row.item.Amount > 0"
                size="sm"
                variant="success"
                @click="reprocess(row.item.Charge_Sheet)"
              >Reprocess</b-dropdown-item>
              <b-dropdown-item
                v-if="row.item.docstatus == 0 && row.item.Amount > 0"
                size="sm"
                @click="submitChargeSheet(row.item.Charge_Sheet)"
              >Bill Patient</b-dropdown-item>

              <b-dropdown-item
                v-if="row.item.docstatus == 0 && row.item.Amount > 0"
                size="sm"
                @click="update_inpatient_outpatent(row.item.Charge_Sheet, row.item.Type == 'Inpatient' ? 'Outpatient' : 'Inpatient', row.item)"
              >
                Set As {{ row.item.Type == 'Inpatient' ? 'Outpatient' : 'Inpatient' }} </b-dropdown-item>

              <b-dropdown-item
                v-if="row.item.docstatus == 0 && row.item.Amount > 0 && row.item.hasOwnProperty('Default_Scheme_Payer')"
                size="sm"
                @click="update_payment_mode(row.item.Charge_Sheet, row.item.Payer == 'Cash Payer' ? row.item.Default_Scheme_Payer : 'Cash Payer', row.item)"
              >
                Change To {{ row.item.Payer == 'Cash Payer' ? row.item.Default_Scheme_Payer : 'Cash Payer'
                }} </b-dropdown-item>

              <b-dropdown-item
                size="sm"
                @click="printChargeSheet(row.item.Charge_Sheet)"
              >Print</b-dropdown-item>
            </b-dropdown>

            <div
              v-if="billing_ongoing && row.item.Charge_Sheet == current_billed_row"
              id="loader"
              class="text-center"
              style=" display: flex; align-items: center; justify-content: center;"
            >
              <b-spinner
                variant="info"
                label="Please wait"
              />
            </div>
          </div>
        </template>

        <template #row-details="row">
          <b-card
            style="box-shadow: none !important;"
            class="cardx"
          >
            <ChargeSheetDetailList
              v-if="row.item.Charge_Sheet"
              :parent="row.item.Charge_Sheet"
              :owner_display="row.item.Full_Name + ' (' + row.item.owner + ')'"
              :owner_phone="row.item.Phone"
              :owner="row.item.owner"
              :doc_status="row.item.docstatus"
              :is_standing_charge="row.item.is_standing_charge"
            />
            <b-button
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #row-payments="row">
          <b-card
            style="box-shadow: none !important;"
            class="cardx"
          >
            <ChargeSheetPayments
              v-if="row.item.Charge_Sheet"
              :parent="row.item.Charge_Sheet"
              :owner="row.item.owner"
            />
            <b-button
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(Charge_Sheet)="row">
          <div
            class="text-left"
            style="width: 100%; "
          >
            <span> <strong>{{ row.item.Charge_Sheet }} </strong> </span>
            <b-row>
              <span v-if="row.item.docstatus == 0">
                <b-badge
                  size="sm"
                  pill
                  style=" justify-content: center;"
                  variant="warning"
                >
                  Pending</b-badge>
              </span>
              <span v-else-if="row.item.docstatus === 1">
                <b-badge
                  size="sm"
                  pill
                  style=" justify-content: center; "
                  variant="success"
                >
                  Completed</b-badge>
              </span>
              <div class="mx-1">
                <b-badge
                  v-if="row.item.Payer === 'Cash Payer'"
                  size="sm"
                  pill
                  style=" justify-content: center;"
                  variant="secondary"
                > {{
                  row.item.Payer }}</b-badge>

                <b-badge
                  v-else
                  size="sm"
                  pill
                  style=" justify-content: center;"
                  variant="secondary"
                >
                  Scheme: {{ row.item.Payer }}</b-badge>

              </div>
            </b-row>
          </div>
        </template>

        <template #cell(Date)="row">
          {{ formatDate(row.item.Date) }}
        </template>

        <template #cell(Amount)="row">
          <strong> KSH {{ formatPrice(row.item.Amount) }} </strong>
        </template>

        <template #cell(Type)="row">
          <div class="text-center">
            <div v-if="row.item.Type === 'Inpatient'">
              <b-badge
                size="sm"
                style="align-self: center; justify-content: center; "
                variant="primary"
              >
                {{ row.item.Type }}</b-badge>
            </div>
            <div v-else-if="row.item.Type === 'Outpatient'">
              <b-badge
                size="sm"
                style="align-self: center; justify-content: center;  "
                variant="primary"
              > {{
                row.item.Type }}</b-badge>
            </div>

          </div>
        </template>

        <template #cell(Invoice)="row">
          <div class="text-center">
            <div>
              <b-button
                v-if="row.item.Invoice"
                size="sm"
                variant="outline-primary"
                @click=" openPatientBill(row.item.Invoice)"
              >
                <strong>{{ row.item.Invoice.replace("BILL-", "") }}</strong></b-button>

              <!-- <div><b-badge v-if="row.item.Completed == 1 || row.item.Completed == '1'" style="align-self: center; justify-content: center;  height:16px; " variant="dark"> {{ 'invoiced'  }}</b-badge></div> -->
            </div>
          </div>
        </template>

      </b-table>
    </b-row>

    <b-row class="justify-content-center">

      <b-col
        v-if="!is_loading"
        class="text-center"
      >

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0"
        />

      </b-col>
      <b-col />
    </b-row>

    <b-row style="margin-bottom: 55px;" />

  </div>
</template>
<script>

import moment from 'moment'
import vSelect from 'vue-select'
import {
  chargeSheetInvoiceMigrate,
  chargeSheetMarkAsIncomplete,
  api,
} from './service'
import UserWarehouse from './components/UserWarehouse.vue'
import ChargeSheetDetailList from './ChargeSheetDetail.vue'
import ChargeSheetPayments from './ChargeSheetPayments.vue'

export default {
  components: {
    UserWarehouse,
    ChargeSheetDetailList,
    ChargeSheetPayments,
  },
  props: {
    patient: String,
  },
  data() {
    return {
      items: [],
      selected: [],
      result: null,
      filter: null,
      charge_sheets_filter_list: [],
      warehouse: null,
      charge_sheets_items: undefined,
      original_charge_sheets_items: [],
      charge_sheet_list_items: [],
      response_message: '',
      showDismissibleAlert: false,
      showDismissibleAlertError: false,
      showDismissibleAlertSuccess: false,

      is_loading: false,

      billing_actions: true,
      billing_ongoing: false,
      current_billed_row: null,

      Generating_Bill: false,

      total_charge_sheet_bill: 0,
      total_charge_sheet_bill_submitted: 0,
      total_charge_sheet_bill_draft: 0,

      total_charge_sheet_cash: 0,
      total_charge_sheet_scheme: 0,

      loaded_amounts: false,

      filter_options: [
        { value: 'default', text: 'Filter Charge Sheets By ...' },
        { value: 'Date_Single', text: 'Filter By Date' },
        { value: 'Date', text: 'Filter By Date Range' },
        { value: 'Warehouse', text: 'Filter By Ware House' },
        { value: 'Invoice', text: 'Filter By Invoice' },
        { value: 'Payment_Mode', text: 'Filter By Payment Mode' },
        { value: 'Biller', text: 'Filter By Biller' },
        { value: 'Admission', text: 'Filter By Admission Period' },
      ],

      selected_filter: 'default',

      single_date: '',

      start_date: '',
      end_date: '',
      max_start_date: '',
      max_end_date: '',
      min_start_date: '',
      min_end_date: '',
      highlightedDates: [],

      ware_houses_filter: '',
      ware_houses_filter_list: [],

      invoices_filter: '',
      invoices_filter_list: [],

      billers_filter: '',
      billers_filter_list: [],

      payment_method_filter: '',
      payment_method_filter_list: [],

      admissions_filter: '',
      admissions_filter_list: [],

      admissions: [],

      updating_charge_sheet: false,

      chargesheettotal: 0,
      headers: [{key:'Charge_Sheet',label:''}, 'Warehouse', 'Amount', 'Type', 'Invoice', 'Date', 'Actions'],

      fields: [{ key: 'creation', sortable: true, label: 'Date' }, { key: 'warehouse', sortable: true }, { key: 'total', sortable: true }, {
        key: 'is_return',
        label: 'Return',
        sortable: true,
        formatter: (value, key, item) => (value ? 'Yes' : 'No'),
      }, {
        key: 'is_inpatient_bill',
        label: 'Inpatient',
        sortable: true,
        formatter: (value, key, item) => (value ? 'Yes' : 'No'),
      }, {
        key: 'completed',
        label: 'Invoiced',
        formatter: (value, key, item) => (value ? 'Yes' : 'No'),
      }, 'actions'],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20, 50, { value: 400, text: 'Show All' }],
    }
  },
  computed: {
    enabledDates() {
      // Array of enabled dates in the format YYYY-MM-DD
      return this.highlightedDates
    },
  },
  watch: {
    patient() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.unsubscibe_payements()
    window.socket.off(this.patient)
  },
  methods: {

    init() {
      window.socket.off(this.patient)
      window.socket.on(this.patient, data => {
        if (!data.type) {
          return
        }
        if (data.count == data.total) {
          this.$bvToast.toast(' Change Sheets have been successfully migrated to target Invoice', {
            title: `Migration Success! ${data.count}/ ${data.total}`,
            variant: 'success',
            autoHideDelay: 10000,
            append: true,
            solid: true,
          })

          return
        }
        this.$bvToast.toast(` migrated successfully ${data.charge_sheet} `, {
          title: `${data.count}/ ${data.total}`,
          variant: 'info',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
      })
      this.subscibe_payements()
      this.get_charge_sheets()
      this.get_charge_sheets_amounts()
      this.get_admissions_period()
    },
    // check status
    openPatientBill(patientBillName) {
      const url = `/app/patient-bill/${patientBillName}`
      window.open(url, '_blank')
    },
    rowClass(item, type) {
      try {
        if (item.docstatus == '0') return 'table-warning'
        if (item.docstatus == '1') return 'table-success'
      } catch (e) {

      }
    },
    refresh() {
      this.get_charge_sheets()
      this.get_charge_sheets_amounts()
      this.get_admissions_period()
      this.selected_filter = 'default'
    },
    formatDate(date) {
      return moment(date).format('Do MMM YYYY , h:mm:ss a')
    },
    formatPrice(value) {
      value = Math.ceil(value)
      value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return value
    },
    setWarehouse(wh) {
      this.warehouse = wh
    },
    onRowSelected(items) {
      this.selected = items
    },
    onChangeFilter(event) {
      this.filter = ''
      this.start_date = ''
      this.end_date = ''
      this.ware_houses_filter = ''
      this.invoices_filter = ''
      this.billers_filter = ''
      this.payment_method_filter = ''
      this.admissions_filter = ''
      this.set_charge_sheets()
      this.process_filtered_amounts()
    },

    dateDisabled(ymd, date) {
      // Convert date to the format YYYY-MM-DD
      const formattedDate = `${ymd[0]}-${String(ymd[1]).padStart(2, '0')}-${String(ymd[2]).padStart(2, '0')}`
      console.log('disabled dates')
      console.log(formattedDate)
      // Check if the formatted date is not in the array of enabled dates
      return !this.enabledDates.includes(formattedDate)
    },
    onChangeChargeSheetFilter() {
      const parent = this
      if (parent.filter == '' || parent.filter == undefined || parent.filter == null) {
        parent.set_charge_sheets()
        parent.process_filtered_amounts()

        return
      }
      console.log(parent.filter)
      parent.charge_sheets_items = []
      const filter_string = String(parent.filter.value).toLowerCase()
      parent.original_charge_sheets_items.forEach((item_, index_) => {
        const chs_name = String(item_.Charge_Sheet).toLowerCase()
        console.log(chs_name)
        if (chs_name.includes(filter_string) || chs_name == filter_string) {
          console.log(chs_name)
          parent.charge_sheets_items.push(
            item_,
          )
        }
      })
      this.process_filtered_amounts()
      parent.totalRows = parent.charge_sheets_items.length
      parent.currentPage = 1
    },
    onChangeAdmissionPeriod(event) {
      const parent = this
      if (parent.admissions_filter == '' || parent.admissions_filter == undefined || parent.admissions_filter == null) {
        parent.set_charge_sheets()
        parent.process_filtered_amounts()
        return
      }
      console.log(parent.admissions_filter)
      parent.admissions.forEach((item_, index_) => {
        if (item_.index == parent.admissions_filter.value) {
          parent.charge_sheets_items = []
          const date_start = new Date(item_.Date_Admitted)
          const date_end = new Date(item_.End_Date)

          // Set the time for date1 to 12:00 AM (00:00)
          date_start.setHours(0, 0, 0, 0)

          // Set the time for date2 to 11:59 PM (23:59)
          date_end.setHours(23, 59, 0, 0)

          parent.original_charge_sheets_items.forEach((item_, index_) => {
            const cs_date = new Date(item_.Date)
            if (cs_date.getTime() >= date_start.getTime() && cs_date.getTime() <= date_end.getTime()) {
              parent.charge_sheets_items.push(
                item_,
              )
            }
          })
          parent.process_filtered_amounts()
          parent.currentPage = 1
          parent.totalRows = parent.charge_sheets_items.length
        }
      })
    },
    onChangeWareHouse(event) {
      const parent = this
      parent.charge_sheets_items = []

      console.log(parent.ware_houses_filter)
      if (parent.ware_houses_filter == '' || parent.ware_houses_filter == undefined || parent.ware_houses_filter == null) {
        parent.set_charge_sheets()
        parent.process_filtered_amounts()

        return
      }
      parent.original_charge_sheets_items.forEach((item_, index_) => {
        if (item_.Warehouse == parent.ware_houses_filter.value) {
          parent.charge_sheets_items.push(
            item_,
          )
        }
      })
      parent.process_filtered_amounts()
      parent.currentPage = 1
      parent.totalRows = parent.charge_sheets_items.length
    },
    onChangeBiller(event) {
      const parent = this
      parent.charge_sheets_items = []
      console.log(this.billers_filter)
      if (parent.billers_filter == '' || parent.billers_filter == undefined || parent.billers_filter == null) {
        parent.set_charge_sheets()
        parent.process_filtered_amounts()

        return
      }

      parent.original_charge_sheets_items.forEach((item_, index_) => {
        if (item_.owner == parent.billers_filter.value) {
          parent.charge_sheets_items.push(
            item_,
          )
        }
      })
      parent.process_filtered_amounts()
      parent.currentPage = 1
      parent.totalRows = parent.charge_sheets_items.length
    },
    onChangeInvoice(event) {
      const parent = this
      parent.charge_sheets_items = []
      console.log(this.invoices_filter)
      parent.original_charge_sheets_items.forEach((item_, index_) => {
        if (item_.Invoice == parent.invoices_filter.value) {
          parent.charge_sheets_items.push(
            item_,
          )
        }
      })
      this.process_filtered_amounts()
    },
    onChangePayment(event) {
      const parent = this
      parent.charge_sheets_items = []
      console.log(this.payment_method_filter)
      parent.original_charge_sheets_items.forEach((item_, index_) => {
        if (item_.Payer == parent.payment_method_filter.value) {
          parent.charge_sheets_items.push(
            item_,
          )
        }
      })
      this.process_filtered_amounts()
      parent.totalRows = parent.charge_sheets_items.length
      parent.currentPage = 1
    },

    update_payment_mode(chargeSheetName, selected_payment_mode, item) {
      // clinical.api.tests.update_items_pw.get_admission_status
      const parent = this
      parent.current_billed_row = chargeSheetName
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_customer_charge_sheet',
        args: {
          charge_sheet_name: chargeSheetName,
          customer_name: selected_payment_mode,
        },
        freeze: false,
      }).then(r => {
        // console.log(r);
        if ('success' in r) {
          item.Payer = selected_payment_mode
          parent.response_message = r.success
          parent.makeVarToast('info')
          parent.current_billed_row = ''
        }
      })
    },

    update_inpatient_outpatent(chargeSheetName, admission_stats, item) {
      const parent = this
      console.log(chargeSheetName)
      parent.current_billed_row = chargeSheetName
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.create_charge_sheet.update_inpatient_outpatient_with_checks',
        args: {
          charge_sheet_name: chargeSheetName,
          inp_out: admission_stats,
          patient: parent.patient,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        if ('success' in r) {
          parent.response_message = r.success
          parent.makeVarToast('success')
          item.Type = admission_stats
          parent.current_billed_row = ''
        } else {
          parent.response_message = r.warning
          parent.makeVarToast('warning')
          parent.current_billed_row = ''
        }
      })
    },

    onChangeFilterSingleDate(event) {
      const parent = this
      console.log(this.single_date)
      if (this.single_date) {
        console.log('selected')
        parent.charge_sheets_items = []

        const date_start = new Date(parent.single_date)
        const date_end = new Date(parent.single_date)
        // Set the time for date1 to 12:00 AM (00:00)
        date_start.setHours(0, 0, 0, 0)

        // Set the time for date2 to 11:59 PM (23:59)
        date_end.setHours(23, 59, 0, 0)

        parent.original_charge_sheets_items.forEach((item_, index_) => {
          const cs_date = new Date(item_.Date)
          if (cs_date.getTime() >= date_start.getTime() && cs_date.getTime() <= date_end.getTime()) {
            parent.charge_sheets_items.push(
              item_,
            )
          }
        })
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      } else {
        this.set_charge_sheets()
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      }
    },
    onChangeFilterStartDate(event) {
      const parent = this
      console.log(this.start_date)

      const minSecond = new Date(event)
      minSecond.setDate(minSecond.getDate() + 1) // Set to one day after the selected date
      this.min_end_date = minSecond
      if (this.start_date && this.end_date) {
        console.log('both selected')
        parent.charge_sheets_items = []
        const date_start = new Date(parent.start_date)
        const date_end = new Date(parent.end_date)

        // Set the time for date1 to 12:00 AM (00:00)
        date_start.setHours(0, 0, 0, 0)

        // Set the time for date2 to 11:59 PM (23:59)
        date_end.setHours(23, 59, 0, 0)

        parent.original_charge_sheets_items.forEach((item_, index_) => {
          const cs_date = new Date(item_.Date)
          if (cs_date.getTime() >= date_start.getTime() && cs_date.getTime() <= date_end.getTime()) {
            parent.charge_sheets_items.push(
              item_,
            )
          }
        })
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      } else {
        this.set_charge_sheets()
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      }
    },
    onChangeFilterEndDate(event) {
      console.log(this.end_date)
      const parent = this
      if (this.start_date && this.end_date) {
        console.log('both selected')
        parent.charge_sheets_items = []
        const date_start = new Date(parent.start_date)
        const date_end = new Date(parent.end_date)
        // Set the time for date1 to 12:00 AM (00:00)
        date_start.setHours(0, 0, 0, 0)

        // Set the time for date2 to 11:59 PM (23:59)
        date_end.setHours(23, 59, 0, 0)

        parent.original_charge_sheets_items.forEach((item_, index_) => {
          const cs_date = new Date(item_.Date)
          if (cs_date.getTime() >= date_start.getTime() && cs_date.getTime() <= date_end.getTime()) {
            parent.charge_sheets_items.push(
              item_,
            )
          }
        })
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      } else {
        this.set_charge_sheets()
        this.process_filtered_amounts()
        parent.totalRows = parent.charge_sheets_items.length
        parent.currentPage = 1
      }
    },
    process_filtered_amounts() {
      const parent = this
      let total = 0
      let total_billed = 0
      parent.charge_sheets_items.forEach((item_, index_) => {
        total += item_.Amount
        if (item_.docstatus == 1) {
          total_billed += item_.Amount
        }
      })
      parent.total_charge_sheet_bill = total
      parent.total_charge_sheet_bill_submitted = total_billed
    },

    reprocess(name) {
      const url = 'billing.billing.api.charge_sheet.hooks.submit.re_run_stock'
      this.api(url, {
        name,

      }).then(r => {
        console.log(r)
        this.submitChargeSheet(name)
        this.get_charge_sheets()
        this.show_alert('Reprocessed successfully, Generate invoice')
      })
    },
    makeVarToast(variant = null) {
      this.$bvToast.toast(this.response_message, {
        title: 'BILLING INFORMATION',
        variant,
        solid: true,
      })
    },
    subscibe_payements() {
      console.log('subscribed')
      const parent = this
      const subscription_channel = `${this.patient}_payments_listener`
      console.log(`subscibed to ${subscription_channel}`)
      // subscribe to channel
      window.socket.on(subscription_channel, data => {
        // this.show_alert({  message: data.body,indicator: 'green' }, 55);
        parent.response_message = data.body
        if (data.title === 'Payment Made') {
          parent.makeToastVar('info', 'Payment')
        }
      })
    },
    unsubscibe_payements() {
      const subscription_channel = `${this.patient}_payments_listener`
      window.socket.off(subscription_channel)
      console.log('unsubscribed')
    },
    makeToastVar(variant = null, title = null) {
      this.$bvToast.toast(this.response_message, {
        title,
        variant,
        solid: true,
      })
    },
    generate_bill() {
      const parent = this
      parent.Generating_Bill = true
      const patient_no = this.patient

      const subscription_value = `${this.patient}generate_bill`
      window.socket.on(subscription_value, data => {
        console.log('initial ver response')

        if (data.title === 'success') {
          parent.response_message = data.body
          parent.makeVarToast('success')
        } else {
          parent.response_message = 'error'
          parent.makeVarToast('danger')
        }

        const subscription_channel = `${this.patient}generate_bill`
        window.socket.off(subscription_channel)

        parent.Generating_Bill = false
        parent.get_charge_sheets()
      })

      const getName = item => item.Charge_Sheet
      // Use the map() method to get an array of names
      const Charge_Sheet_Names_Array = parent.charge_sheets_items.map(getName)

      api({
        method:
                    'billing.billing.api.charge_sheet.sales_invoice_item_sql.charge_sheets_list_process',
        args: {
          charge_sheets_payload: Charge_Sheet_Names_Array,
          patient: patient_no,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        if ('success' in r) {
          parent.response_message = r.success
          parent.makeVarToast('success')
          parent.Generating_Bill = false
          parent.get_charge_sheets()
        } else {
          parent.response_message = r.info
          parent.makeVarToast('info')
          parent.Generating_Bill = false
          parent.get_charge_sheets()
        }
      })
    },
    submitChargeSheet(charge_sheet_name) {
      const parent = this

      this.showDismissibleAlert = false
      this.showDismissibleAlertSuccess = false
      this.showDismissibleAlertError = false
      console.log(charge_sheet_name)

      parent.current_billed_row = charge_sheet_name
      parent.billing_actions = false
      parent.billing_ongoing = true
      api({
        method:
                    'billing.billing.api.payment_integration.process_payment.submit_chargesheet',
        args: {
          name: charge_sheet_name,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        // process stalled payment
        if ('Need_Payment' in r) {
          parent.response_message = r.Need_Payment
          parent.makeVarToast('danger')
          parent.showDismissibleAlert = false
          parent.showDismissibleAlertSuccess = false

          parent.showDismissibleAlertError = true

          parent.billing_actions = true
          parent.billing_ongoing = false
          parent.current_billed_row = ''
        }

        if ('Not_Equal' in r) {
          parent.response_message = r.Not_Equal
          parent.makeVarToast('danger')

          parent.showDismissibleAlertSuccess = false
          parent.showDismissibleAlertError = true
          parent.showDismissibleAlert = false

          parent.billing_actions = true
          parent.billing_ongoing = false
          parent.current_billed_row = ''
        }

        if ('Submitted' in r) {
          parent.response_message = r.Submitted
          parent.makeVarToast('success')
          parent.showDismissibleAlert = false

          parent.showDismissibleAlertError = false
          parent.showDismissibleAlertSuccess = false
          this.get_charge_sheets()

          parent.billing_actions = true
          parent.billing_ongoing = false
          parent.current_billed_row = ''
        }
      })
    },
    printChargeSheet(name) {
      const w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                + 'doctype='}${encodeURIComponent('Charge Sheet')
      }&name=${encodeURIComponent(name)
      }&format=${encodeURIComponent('billing')
      }&_lang=en&no_letterhead=0&letterhead=MTRH%20Default&settings=%7B%7D&trigger_print=1`))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },
    get_charge_sheets() {
      const parent = this
      parent.is_loading = true
      const patient_number = this.patient
      console.log(`This patient get charge sheets ${patient_number}`)
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.charge_sheet_list.get_charge_sheets_frontend',
        args: {
          patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log('new response')
        console.log(r)
        parent.charge_sheets_items = r
        parent.original_charge_sheets_items = r
        parent.set_charge_sheets()
      })
    },
    set_charge_sheets() {
      const parent = this
      parent.charge_sheets_items = []
      parent.charge_sheets_items = parent.original_charge_sheets_items.map(item => ({
        ...item,
        selected: false,
      }))

      const firstItem = parent.original_charge_sheets_items.slice(-1)[0]
      const lastItem = parent.original_charge_sheets_items.slice(-1)[0]

      const minFirst = new Date(firstItem.Date)
      minFirst.setDate(minFirst.getDate() - 1) // Set to one day after the selected date
      parent.min_start_date = minFirst

      const today = new Date()
      const maxLast = new Date(today)
      maxLast.setDate(maxLast.getDate() + 1) // Set to one day after the selected date
      parent.max_end_date = maxLast
      parent.max_start_date = maxLast

      parent.set_highlighted_dates()
      parent.set_warehouses_list()
      parent.set_billers_list()
      parent.set_invoices_list()
      parent.set_payers_list()
      parent.set_charge_sheets_filter()
      parent.is_loading = false
      parent.totalRows = parent.charge_sheets_items.length
    },
    get_charge_sheets_amounts() {
      const parent = this
      const patient_number = parent.patient
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.charge_sheet_list.get_charge_sheet_totals',
        args: {
          patient: patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.total_charge_sheet_bill = r.total
        parent.total_charge_sheet_bill_submitted = r.total_amount_completed
        parent.total_charge_sheet_bill_draft = r.total_amount_draft
        parent.total_charge_sheet_cash = r.total_cash_amount
        parent.total_charge_sheet_scheme = r.total_scheme_amount
        parent.loaded_amounts = true
        parent.is_loading = false
      })
    },
    get_admissions_period() {
      const parent = this
      const patient_number = parent.patient
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.charge_sheet_list.get_inpatient_records',
        args: {
          patient: patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.admissions = r
        parent.set_admissions()
      })
    },
    set_admissions() {
      const parent = this
      // map index
      parent.admissions = parent.admissions.map((item, index) => ({ ...item, index: index + 1 }))

      parent.admissions.forEach((item_, index_) => {
        parent.admissions_filter_list.push(
          {
            value: item_.index,
            label: item_.Label,
          },
        )
      })

      // filter array distinct
      const arrayOfObjects = parent.admissions_filter_list

      const distinctNames = new Set()
      parent.admissions_filter_list = arrayOfObjects.filter(obj => {
        if (!distinctNames.has(obj.value)) {
          distinctNames.add(obj.value)
          return true
        }
        return false
      })
    },
    set_warehouses_list() {
      const parent = this

      parent.charge_sheets_items.forEach((item_, index_) => {
        parent.ware_houses_filter_list.push(
          {
            value: item_.Warehouse,
            label: item_.Warehouse,
          },
        )
      })

      // filter array distinct
      const arrayOfObjects = parent.ware_houses_filter_list

      const distinctNames = new Set()
      parent.ware_houses_filter_list = arrayOfObjects.filter(obj => {
        if (!distinctNames.has(obj.value)) {
          distinctNames.add(obj.value)
          return true
        }
        return false
      })
    },
    set_billers_list() {
      const parent = this

      parent.charge_sheets_items.forEach((item_, index_) => {
        parent.billers_filter_list.push(
          {
            value: item_.owner,
            label: item_.Full_Name, // +' (' +item_.Phone+')'
          },
        )
      })

      // filter array distinct
      const arrayOfObjects = parent.billers_filter_list

      const distinctNames = new Set()
      parent.billers_filter_list = arrayOfObjects.filter(obj => {
        if (!distinctNames.has(obj.value)) {
          distinctNames.add(obj.value)
          return true
        }
        return false
      })
    },

    set_invoices_list() {
      const parent = this

      parent.charge_sheets_items.forEach((item_, index_) => {
        const label = item_ && (item_.Invoice !== '' && item_.Invoice !== null && item_.Invoice !== undefined) ? item_.Invoice : 'Uninvoiced Charge Sheets'

        parent.invoices_filter_list.push(
          {
            value: item_.Invoice,
            label,
          },
        )
      })

      console.log(parent.invoices_filter_list)
      // filter array distinct
      // replace null with string
      const arrayOfObjects = parent.invoices_filter_list

      const distinctNames = new Set()
      parent.invoices_filter_list = arrayOfObjects.filter(obj => {
        if (!distinctNames.has(obj.value)) {
          distinctNames.add(obj.value)
          return true
        }
        return false
      })
    },

    set_payers_list() {
      const parent = this

      parent.charge_sheets_items.forEach((item_, index_) => {
        parent.payment_method_filter_list.push(
          {
            value: item_.Payer,
            label: item_.Payer,
          },
        )
      })

      // filter array distinct
      const arrayOfObjects = parent.payment_method_filter_list

      const distinctNames = new Set()
      parent.payment_method_filter_list = arrayOfObjects.filter(obj => {
        if (!distinctNames.has(obj.value)) {
          distinctNames.add(obj.value)
          return true
        }
        return false
      })
    },

    set_charge_sheets_filter() {
      const parent = this
      parent.charge_sheets_items.forEach((item_, index_) => {
        parent.charge_sheets_filter_list.push(
          {
            value: item_.Charge_Sheet,
            label: item_.Charge_Sheet,
          },
        )
      })
    },

    set_highlighted_dates() {
      const parent = this
      parent.highlightedDates = []
      parent.charge_sheets_items.forEach((item_, index_) => {
        parent.highlightedDates.push(new Date(item_.Date))
      })
      console.log(parent.highlightedDates)
    },

    showAddChargeDialog() {
      if (!frappe.user_roles.includes('Revenue Accountant')) {
        this.$bvToast.toast('You don\'t have role REVENUE ACCOUNTANT', {
          title: 'Permission Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })

        return
      }

      const context = this
      const x = new frappe.ui.Dialog({
        title: 'Select Target Invoice',
        fields: [
          {
            label: 'Target Sales Invoice',
            fieldname: 'invoice',
            fieldtype: 'Link',
            options: 'Sales Invoice',
            reqd: true,
            filters: { patient: context.patient, docstatus: 0 },
          },
          {
            label: 'No,I just want a copy',
            fieldname: 'is_copy',
            fieldtype: 'Check',
          },
        ],
        primary_action_label: 'migrate',
        primary_action: values => {
          const { invoice } = values
          const { is_copy } = values
          context.$bvToast.toast(`Migration to invoice ${invoice} has started`, {
            title: 'Migration Started!',
            variant: 'success',
            autoHideDelay: 10000,
            append: true,
            solid: true,
          })
          context.hitMigrateApi(invoice, is_copy)
          x.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action: values => {
          x.hide()
        },
      })
      x.show()
    },

    showUpdateCustomerDialog() {
      const context = this
      const x = new frappe.ui.Dialog({
        title: 'Update Customer',
        fields: [
          {
            label: 'Customer',
            fieldname: 'customer',
            fieldtype: 'Link',
            options: 'Customer',
            reqd: true,
            filters: {},
          },
        ],
        primary_action_label: 'migrate',
        primary_action: values => {
          const { invoice } = values
          context.$bvToast.toast(`Migration to invoice ${invoice} has started`, {
            title: 'Migration Started!',
            variant: 'success',
            autoHideDelay: 10000,
            append: true,
            solid: true,
          })
          context.hitMigrateApi(invoice)
          x.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action: values => {
          x.hide()
        },
      })
      x.show()
    },
    hitMigrateApi(invoice, is_copy) {
      const charge_sheets = this.selected.map(item => item.Charge_Sheet)
      const data = { invoice, charge_sheets, is_copy }

      chargeSheetInvoiceMigrate(data).then(result => {
        this.show_alert('Migration complete')
      })
    },

    markAsIncomplete() {
      const charge_sheets = this.selected.map(item => item.Charge_Sheet)
      const data = { charge_sheets }

      chargeSheetMarkAsIncomplete(data).then(result => {
        this.show_alert('Charge Sheets Updated')
        frappe.utils.play_sound('submit')
        this.get_charge_sheets()
      })
    },
  },

}
</script>
<style>
.highlighted-date {
    background-color: #399000;
    /* Set your preferred highlight color here */
}

.custom-selectable .selected {
    background-color: #6b6b6b;
    /* Replace with your desired color */
    color: #ffffff;
    /* Replace with your desired text color */
}</style>
