<template>
  <span> {{ currentName || item_code }}</span>
</template>
<script>
export default {
  props: {
    item_code: String,
  },
  data() {
    return {
      currentName: null,
    }
  },
  created() {
    this.docGetVal('Item', this.item_code, 'item_name').then(result => {
      this.currentName = result
    })
  },

}

</script>
