<template>
  <div style="margin-bottom:50px;">

    <b-modal
      v-model="verifyMpesaModalVisible"
      title="Verify Mpesa Payment"
      hide-footer
    >
      <b-form @submit.prevent="submitVerifyMpesa">
        <b-form-group
          label="Name"
          label-for="verify_party"
        >
          <b-form-input
            v-model="verify_party"
            type="text"
            readonly
          />
        </b-form-group>

        <b-form-group
          label="Transaction Code"
          label-for="verify_transaction_code"
        >
          <b-form-input
            v-model="verify_transaction_code"
            type="text"
            required
          />
        </b-form-group>

        <b-button
          size="sm"
          class="mr-1 mb-2"
          type="submit"
          variant="primary"
        >
          Check Transaction
        </b-button>
        <b-button
          size="sm"
          class="mb-2"
          variant="secondary"
          @click="cancelVerifyMpesa"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <b-modal
      v-model="chargesheetDetailsModal"
      style=" max-width: 90%; width: 90%;"
      s
      hide-footer
      @hide="onChargesheetDetailsModalClose"
    >
      <template #modal-title>
        Charge Sheet Details: <b> {{ active_charge_sheet_name }} </b>
      </template>

      <template #default>
        <ReceiptsChargeSheet
          :passed-charge-sheet-name="active_charge_sheet_name"
          :passed_owner="'owner'"
        />
      </template>
    </b-modal>

    <b-modal
      v-model="chargesheeToBePaidModal"
      size="lg"
      hide-footer
      @hide="onChargesheeToBePaidModalClose"
    >
      <template #modal-title>
        Add Payment For: <b> {{ patientName }} </b>
      </template>

      <!-- <template #default> -->

      <h4 v-if="unpaid_charge_sheets.length">
        <b>Recent Charge Sheets</b>
      </h4>
      <p
        v-if="appointments.length"
        style="color: gray;"
      >
        Appointment to be paid for:
      </p>
      <b-list-group
        v-for="(appointment, index) in appointments"
        :key="index"
      >
        <b-list-group-item
          variant="light"
          style="cursor: pointer; "
          class="d-flex justify-content-between align-items-center"
        >
          <b>Name: <span>{{ appointment.Name }}</span></b>
          <!-- <b>Warehouse: <span>{{charge_sheet.Warehouse}}</span></b> -->
          <b>Date: <span>{{ appointment.Date }}</span></b>
          <b>Amount: <span>{{ appointment.Consultation_Fee }}</span></b>
          <b-badge
            variant="warning"
            pill
          >
            Status:Pending Payment
          </b-badge>
        </b-list-group-item>

      </b-list-group>

      <p
        v-if="unpaid_charge_sheets.length"
        style="color: gray;"
      > charge sheets to be paid for: <b>Total: {{
        sumOfChargSheets }}</b></p>

      <b-list-group
        v-for="(charge_sheet, index) in unpaid_charge_sheets"
        :key="index"
      >
        <b-list-group-item
          :variant="calculateVariant(index)"
          style="cursor: pointer; "
          class="d-flex justify-content-between align-items-center"
          @click="toggleCollapse(index)"
        >
          <b-form-checkbox
            v-if="selectStates[index]"
            :id="'checkbox' + index"
            v-model="selectStates[index]"
          />
          <b>Name: <span>{{ charge_sheet.Charge_Sheet }}</span></b>
          <!-- <b>Warehouse: <span>{{charge_sheet.Warehouse}}</span></b> -->
          <b>Type: <span>{{ charge_sheet.Type }}</span></b>
          <b>Amount: <span>{{ charge_sheet.Amount }}</span></b>
          <b-badge
            class="mx-1"
            :variant="selectStates[index] ? 'info' : 'warning'"
          >
            Status:{{ selectStates[index] ? 'To Be Paid':'Pending Payment' }}
          </b-badge>
          <b-button
            :variant="selectStates[index] ? 'primary' : 'outline-primary'"
            style="z-index: 20px;"
            @click.stop
            @click="toggleSelect(index)"
          > {{ selectStates[index] ? 'Cancel' : 'Pay' }}
          </b-button>
        </b-list-group-item>
        <b-collapse v-model="collapseStates[index]">
          <div>
            <ReceiptsChargeSheet
              :passed-charge-sheet-name="charge_sheet.Charge_Sheet"
              :passed_owner="'owner'"
            />
          </div>
        </b-collapse>

      </b-list-group>

      <br>
      <hr>

      <b-form
        @submit="onSubmitPayment"
        @reset="onReset"
      >
        <b-form-group
          id="input-group-1"
          label="Patient Number:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="Manual_Pay.Patient"
            type="text"
            placeholder="Enter patient"
            required
            readonly
          />
        </b-form-group>

        <b-form-group label="Payment Mode:">
          <v-select
            v-model="Manual_Pay.Payment_Mode"
            placeholder="Payment Mode"
            required
            :options="payment_modes"
            :rules="[v => !!v || 'Payment Mode is required']"
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Reference No:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="Manual_Pay.Reference_No"
            placeholder="Enter Reference No"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Amount:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="Manual_Pay.Amount"
            placeholder="Enter Amount"
            type="number"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Verify Amount:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="Manual_Pay.Verified_Amount"
            placeholder="Enter Amount"
            type="number"
            required
          />
        </b-form-group>

        <b-button
          size="sm"
          class="mr-1"
          :disabled="submitting_manual_pay"
          type="submit"
          variant="primary"
        >{{
          submitting_manual_pay ? 'Submitting...' : 'Submit payment' }}</b-button>
        <b-button
          v-show="!submitting_manual_pay"
          size="sm"
          :disabled="submitting_manual_pay"
          type="reset"
          variant="danger"
          @click="chargesheeToBePaidModal = false"
        >Cancel</b-button>
      </b-form>
      <!-- <ReceiptsChargeSheet :passed_charge_sheet_Name = "active_charge_sheet_name" :passed_owner = "'owner'"  /> -->
      <!-- </template> -->
    </b-modal>

    <b-row
      v-if="showDismissibleAlert || showDismissibleAlertSuccess || showDismissibleAlertError"
      class="mt-1 mb-2"
    >
      <b-col>
        <div class="d-flex flex-column mb-6 bg-surface-variant">
          <v-sheet class="ma-2 pa-2">
            <b-alert
              v-model="showDismissibleAlert"
              variant="info"
              dismissible
            >
              {{ response_message }}
            </b-alert>

            <b-alert
              v-model="showDismissibleAlertSuccess"
              variant="success"
              dismissible
            >
              {{ response_message }}
            </b-alert>

            <b-alert
              v-model="showDismissibleAlertError"
              variant="danger"
              dismissible
            >
              {{ response_message }}
            </b-alert>
          </v-sheet>
        </div>
      </b-col>
    </b-row>

    <b-row style="width:100%">
      <div>
        <b-badge
          pill
          variant="primary"
          style=" margin-right: 3px; margin-bottom: 15px;"
        >
          <div v-if="paymentItems && paymentItems.length">Total: {{ paymentItems.filter(x => x.received_amount > 0
          ).map(x => Math.ceil(parseFloat(x.received_amount))).reduce((accumulator, currentValue) => {
            return
            accumulator + currentValue;
          }, 0).toLocaleString('en-KE', { style: 'currency', currency: 'KES' })
          }}
          </div>
        </b-badge>
      </div>
    </b-row>

    <b-row
      style="margin-top: 5px;"
      class="w-100 mb-1"
      align-h="bet"
    >

      <div class="text-center">
        <b-button-group class="mb-1">
          <b-button
            v-b-tooltip.hover
            size="sm"
            title="Total amount paid from 23 March 2023"
            :pressed="true"
            variant="dark"
          > Total Paid: <strong> KSH {{ formatPrice(total_patient_payments)
          }}</strong> </b-button>
          <b-button
            v-b-tooltip.hover
            size="sm"
            title="Total amount available for dispensing services"
            :pressed="true"
            variant="light"
          > Available:<strong> KSH {{
            formatPrice(total_patient_unused_payments) }} </strong> </b-button>
        </b-button-group>

      </div>

      <b-row>

        <div><b-button
          size="sm"
          style="margin-right:4px;"
          variant="primary"
          @click="refresh_payments()"
        >
          <i class="fa-solid fa-arrows-rotate" />  </b-button> </div>

        <div v-if="hidden">
          <b-button
            v-if="!allocating_inpatient_allocations"
            size="sm"
            style="margin-right:4px;"
            variant="warning"
            @click="ipatient_allocations()"
          > Reconcile Payments </b-button>

          <div
            class="justify-content-center align-self-center"
            style=" display: flex; align-items: center; justify-content: center;"
          >
            <b-spinner
              v-if="allocating_inpatient_allocations"
              style="margin-right:4px; margin-top: 6px; align-self: center; justify-self: center;"
              variant="primary"
              small
              label="Working..."
            />
            <!-- <strong style="margin-right:4px;" >Working...</strong> -->
          </div>

        </div>

        <div v-if="showAddPayment == 1"><b-button
          size="sm"
          style="margin-right:4px;"
          variant="primary"
          @click="verifyMpesaPayment"
        >Verify Funsoft Payment </b-button> </div>

        <div v-if="showAddPayment == 0"><b-button
          size="sm"
          style="margin-right:4px;"
          variant="dark"
          @click="chargesheeToBePaidModal = !chargesheeToBePaidModal"
        ><i class="fa-solid fa-plus" /> Payment </b-button> </div>

        <div v-if="this.getUserSession().user === 'Administrator' || this.hasRole('Payment Master Approver')">
          <b-button
            v-if="showAddPayment == 1"
            size="sm"
            style="margin-right:4px;"
            variant="primary"
            @click="verifyKCBPayment"
          >Verify KCB </b-button>
        </div>

        <div
          v-if="checking_mpesa"
          id="loader"
          class="justify-content-center align-self-center"
          style=" display: flex; align-items: center; justify-content: center;"
        >
          <b-spinner
            small
            label="Please wait"
          />
          <strong style="margin-right:4px;">Working,Please Wait...</strong>
        </div>

        <div
          v-if="mpesa_verify_button"
          id="mpesa_div"
        ><b-button
          size="sm"
          style="margin-right:4px;"
          variant="success"
          @click="verifyMpesaApi"
        >Verify Payment</b-button>
        </div>

      </b-row>
    </b-row>

    <b-row style="margin-bottom: 35px; width: 100%; margin-left : 0px;margin-right : 0px;">

      <b-table
        style="width: 100%;"
        responsive="sm"
        bordered
        stacked="sm"
        small
        striped
        :fields="headers_mpesa_kcb"
        :items="MpesaKcbPayments"
        :empty-text="`No items `"
        hover
        head-row-variant="primary"
        :show-empty="true"
        :busy="isBusy_2"
      >

        <template #cell(actions)="row">
          <div class="text-center">
            <b-button
              size="sm"
              variant="outline-secondary"
              class="mr-2"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Less' : 'More' }}
            </b-button>

            <b-button
              size="sm"
              variant="outline-dark"
              class="mr-2"
              @click="printParentReceipt(row.item)"
            >
              <i
                class="fa fa-print"
                aria-hidden="true"
              />
            </b-button>
          </div>
        </template>

        <template #row-details="row">

          <b-table
            small
            :filter="row.item.Reference"
            :filter-function="filterTable"
            :fields="headers"
            style="width: 100%"
            responsive="sm"
            bordered
            striped
            :items="PaymentEntries"
            :empty-text="`No items `"
            :empty-filtered-text="`No items`"
            stacked="md"
            hover
            head-row-variant="primary"
            :show-empty="true"
            :tbody-tr-class="rowClass"
            :busy="isBusy"
          >
            <template #cell(actions)="row">
              <div class="text-center">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="printReceipt(row.item)"
                >
                  <i
                    class="fa fa-print"
                    aria-hidden="true"
                  /> Print
                </b-button>
              </div>

            </template>

            <template #cell(Allocated_ChargeSheet)="row">
              <span
                style="cursor: pointer;"
                @click="onChargesheetDetailsModalClicked(row.item.Allocated_ChargeSheet)"
              > <b> {{
                row.item.Allocated_ChargeSheet }} </b> </span>
            </template>

            <template #cell(Date)="row">
              {{ formatDate(row.item.Date) }}
            </template>

            <template #cell(Payment_Status)="row">
              <div class="text-center">
                <div v-if="row.item.Payment_Status === 'Submitted'">
                  <b-badge
                    style="align-self: center; justify-content: center;  height:16px; "
                    variant="success"
                  > Paid </b-badge>
                </div>
                <div v-else-if="row.item.Payment_Status === 'Draft'">
                  <b-badge
                    style="align-self: center; justify-content: center;  height:16px;"
                    variant="warning"
                  > Processing </b-badge>
                </div>
                <div v-else-if="row.item.Payment_Status === 'Cancelled'">
                  <b-badge
                    style="align-self: center; justify-content: center;  height:16px;"
                    variant="danger"
                  > Cancelled </b-badge>
                </div>
              </div>
            </template>

            <template #cell(Utilized)="row">
              <div class="text-center">
                <div v-if="row.item.Utilized === 'Yes'">
                  <b-badge
                    style="align-self: center; justify-content: center;  height:16px; "
                    variant="info"
                  > YES</b-badge>
                </div>
                <div v-else-if="row.item.Utilized === 'No'">
                  <b-badge
                    style="align-self: center; justify-content: center;  height:16px;"
                    variant="secondary"
                  > NO</b-badge>
                </div>
              </div>
            </template>

            <template #cell(Payment)="row">
              <div class="text-left">
                <strong
                  style="cursor: pointer;"
                  :style="row.item.Utilized === 'No' ? 'color: rgb(0, 0, 166);' : 'color: rgb(1, 1, 1)'"
                  @click="row.item.Utilized === 'No' ? open_payments_new_tab('Payment Entry', row.item.Payment, row.item.Payment) : do_nothing('The Amount has been utilized in a bill: ' + row.item.Allocated_ChargeSheet)"
                >
                  {{ row.item.Payment }} </strong>
                <!-- <div v-if="row.item.Utilized === 'Yes'">
                                        <b-badge style="align-self: center; justify-content: center;  height:16px; " variant="info"> YES</b-badge>
                                    </div>
                                    <div v-else-if="row.item.Utilized === 'No'">
                                        <b-badge style="align-self: center; justify-content: center;  height:16px;" variant="dark"> NO</b-badge>
                                    </div> -->
              </div>
            </template>

            <template #cell(Amount)="row">
              KSH <strong> {{ formatPrice(row.item.Amount) }} </strong>
            </template>

          </b-table>

        </template>

        <template #cell(Date)="row">
          {{ formatDate(row.item.Date) }}
        </template>

        <template #cell(Amount)="row">
          <strong> KSH {{ formatPrice(row.item.Amount) }} </strong>
        </template>

      </b-table>
    </b-row>

    <div />
    <div />
    <b-row />
    <b-row />

  </div>
</template>

<script>
import moment from 'moment'
import ReceiptsChargeSheet from './ReceiptsChargeSheet.vue'
//
import {
  savePaymentEntry,
  savePrePayment,
  api,
} from './service'

export default {
  name: 'Receipts',
  components:
    {
      ReceiptsChargeSheet,
    },
  filters: {
    // Filter definitions
    dateFormat(date) {
      const current_datetime = new Date(date)
      return `${current_datetime.getFullYear()}-${current_datetime.getMonth() + 1}-${current_datetime.getDate()} ${
        current_datetime.getHours()}:${current_datetime.getMinutes()}:${current_datetime.getSeconds()}`
    },
  },
  props: {
    patient_number: {
      type: String,
      default: ' ',
      required: true,
    },
    customerNumber: {
      type: String,
      default: ' ',
      required: true,
    },
    patientName: {
      type: String,
      default: ' ',
      required: true,
    },
  },
  data() {
    return {
      verifyMpesaModalVisible: false,
      hidden: false,
      paymentItems: undefined,
      PaymentEntries: undefined,
      MpesaKcbPayments: undefined,
      headers: ['Payment', 'Payment_Status', 'Amount', 'Utilized', 'Allocated_ChargeSheet', 'Date'],
      headers_mpesa_kcb: ['Type', 'Reference', 'Amount', 'Patient', 'Phone', 'Date', 'actions'],
      showDismissibleAlert: false,
      showDismissibleAlertError: false,
      showDismissibleAlertSuccess: false,
      response_message: '',
      filter: null,
      parent_filter: null,
      fields: ['name', 'status', 'posting_date', 'received_amount', 'paid_to', 'creation', 'actions'],
      checking_mpesa: false,
      mpesa_verify_button: true,
      isBusy: false,
      isBusy_2: false,

      total_patient_payments: 0,
      total_patient_unused_payments: 0,

      chargesheetDetailsModal: false,
      active_charge_sheet_name: '',

      allocating_inpatient_allocations: false,
      showAddPayment: 0,

      chargesheeToBePaidModal: false,
      payment_modes: [],
      Manual_Pay: {
        Patient: this.patient_number,
        Payment_Mode: '',
        Reference_No: '',
        Amount: '',
        Verified_Amount: '',
      },
      submitting_manual_pay: false,

      unpaid_charge_sheets: [],
      appointments: [],

      collapseStates: [],
      selectStates: [],

      selected_charge_sheets_names: [],

      total_amount_to_be_paid: 0,
    }
  },
  computed: {
    sumOfChargSheets() {
      // Use reduce to sum the 'value' property based on the Boolean array
      return this.unpaid_charge_sheets.reduce((sum, obj, index) =>
      // Add the 'value' property to the sum if the corresponding Boolean is true
        (this.selectStates[index] ? sum + obj.Amount : sum),
      0)
    },
  },
  watch: {
    patient_number() {
      this.getPayments()
    },
  },
  mounted() {
    this.get_total_unsed_payments()
    this.get_total_payments()
    this.subscibe_payements()
    this.get_payment_modes()
    this.get_unpaid_chargesheets()
    //    this.ipatient_allocations();
  },
  beforeUnmount() {
    //    this.subscibe_payements();
  },
  beforeDestroy() {
    this.unsubscibe_payements()
  },
  created() {
    this.getPayments()
    this.getIsInternalFacilitySettings()
    //
  },
  methods: {
    toggleCollapse(index) {
      // Toggle the collapse state for the clicked item
      console.log(index)
      this.$set(this.collapseStates, index, !this.collapseStates[index])
      console.log(this.collapseStates)
    },
    toggleSelect(index) {
      // Toggle the collapse state for the clicked item
      console.log(index)
      this.$set(this.selectStates, index, !this.selectStates[index])
      console.log(this.selectStates)
      this.Manual_Pay.Amount = this.sumOfChargSheets
      this.Manual_Pay.Verified_Amount = this.sumOfChargSheets
    },
    calculateVariant(index) {
      // Determine the variant based on collapseStates and selectStates
      if (this.collapseStates[index] && this.selectStates[index]) {
        return 'secondary' // Both conditions met
      } if (this.collapseStates[index]) {
        return 'primary' // One condition met
      }
      if (this.selectStates[index]) {
        return 'secondary' // One condition met
      }

      return 'light' // Neither condition met
    },
    onSubmitPayment(event) {
      event.preventDefault()
      console.log(this.Manual_Pay)
      const parent = this

      if (parent.Manual_Pay.Amount != parent.Manual_Pay.Verified_Amount) {
        parent.response_message = 'Amounts for payment do not tally, please confirm that they match.'
        parent.makeToastVar('warning', 'Payment')
        return
      }

      if (parent.Manual_Pay.Payment_Mode == null || parent.Manual_Pay.Payment_Mode == '' || parent.Manual_Pay.Payment_Mode == undefined) {
        parent.response_message = 'Payment Mode is Required'
        parent.makeToastVar('warning', 'Payment')
        return
      }

      if (parent.Manual_Pay.Amount == null || parent.Manual_Pay.Amount == '' || parent.Manual_Pay.Amount == undefined) {
        parent.response_message = 'Amount  is Required'
        parent.makeToastVar('warning', 'Payment')
        return
      }

      parent.submitting_manual_pay = true
      api({
        method:
                    'billing.billing.api.accounts.patients.create_prepayment',
        args: {
          customer_name: parent.customerNumber,
          patient_name: parent.patient_number,
          amount: parent.Manual_Pay.Amount,
          // account_for_change:values.account_for_change,
          change_amount: parent.Manual_Pay.Amount,
          amount_tendered: parent.Manual_Pay.Verified_Amount,
          reference_no: parent.Manual_Pay.Reference_No,
          // paid_to: values.paid_to
          mode_of_payment: parent.Manual_Pay.Payment_Mode.Type,
        },
        freeze: false,
      }).then(r => {
        parent.$emit('newPaymentEntry')

        if ('success' in r) {
          if (parent.sumOfChargSheets > 0) {
            console.log('charge sheets to be submitted')
            parent.selected_charge_sheets_names = []

            parent.unpaid_charge_sheets.forEach((chs, index) => {
              if (parent.selectStates[index]) {
                parent.selected_charge_sheets_names.push(chs.Charge_Sheet)
              }
            })

            console.log(parent.selected_charge_sheets_names)
            parent.response_message = r.success
            parent.makeToastVar('success', 'Payment')

            api({
              method:
                                'billing.billing.api.payment_integration.Payments_frontend.submit_charge_sheet_from_payments',
              args: {
                payload: parent.selected_charge_sheets_names,

              },
              freeze: false,
            }).then(r => {
              if ('Submitted' in r) {
                parent.response_message = r.Submitted
                parent.makeToastVar('success', 'Payment')
                parent.chargesheeToBePaidModal = false
                parent.submitting_manual_pay = false
                parent.getPayments()
                parent.collapseStates = []
                parent.selectStates = []
                this.get_unpaid_chargesheets()
              }
            })
          } else {
            parent.response_message = r.success
            parent.makeToastVar('success', 'Payment')
            parent.chargesheeToBePaidModal = false
            parent.submitting_manual_pay = false
            parent.getPayments()
          }
        }

        if ('warning' in r) {
          parent.response_message = r.warning
          parent.makeToastVar('warning', 'Payment')
          parent.submitting_manual_pay = false
        }
      })
    },
    onReset(event) {
    },
    getIsInternalFacilitySettings() {
      const x = this.docGetSingleValue('Facility Settings', 'is_internal')
        .then(res => {
          this.showAddPayment = res
        })
    },
    onChargesheetDetailsModalClicked(charge_sheet) {
      this.chargesheetDetailsModal = true
      this.active_charge_sheet_name = charge_sheet
    },
    onChargesheetDetailsModalClose() {
      this.chargesheetDetailsModal = false
    },
    onChargesheeToBePaidModalClose() {
      this.chargesheeToBePaidModal = false
    },
    verifyKCBPayment() {
      const parent = this
      const d = new frappe.ui.Dialog({
        title: `Enter Payment details for ${parent.patientName} (${parent.patient_number})`,
        fields: [
          {
            label: 'Receipt Number',
            fieldname: 'receipt_number',
            fieldtype: 'Data',
            reqd: true,
          },
          {
            label: 'Amount Paid (KES)',
            fieldname: 'transaction_amount',
            fieldtype: 'Currency',
            reqd: true,
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          console.log(values)
          this.api(
            {
              method: 'billing.billing.api.payment_integration.kcb_endpoints.manually_verify_kcb_payment',
              args: {
                patient: parent.patient_number,
                transaction_amount: values.transaction_amount,
                receipt_number: values.receipt_number,
              },
              callback() {
                this.show_alert({
                  message: ('Payment verification success'),
                  indicator: 'green',
                }, 5)
                d.hide()
                parent.refresh_payments()
              },
            },
          )
        },
      })
      d.show()
    },
    // check status
    rowClass(item, type) {
      if (item.Utilized === 'Yes') return 'table-info'
    },
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
    toast(data) {
      this.$bvToast.toast(data.success, {
        title: data.title,
        autoHideDelay: 10000,
        appendToast: true,
      })
    },
    filterTable(row, filter) {
      if (row.KCB_reference === filter || row.MPESA_reference === filter || row.Bank_reference === filter) {
        return true
      }
      return false
    },
    formatDate(date) {
      return moment(date).format('Do MMM YYYY , h:mm:ss a')
    },
    formatPrice(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    get_total_payments() {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.process_payment.get_all_payments',
        args: {
          patient: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.total_patient_payments = r
        console.log('total  patient payments')
        console.log(parent.total_patient_payments)
      })
    },
    get_unpaid_chargesheets() {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_unpaid_charge_sheets',
        args: {
          patient_number: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.unpaid_charge_sheets = r.charge_sheets
        parent.appointments = r.appointments
        parent.collapseStates = Array(parent.unpaid_charge_sheets.length).fill(false)
        parent.selectStates = Array(parent.unpaid_charge_sheets.length).fill(false)
        console.log(parent.collapseStates)
      })
    },
    get_total_unsed_payments() {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.process_payment.get_unallocated_payment',
        args: {
          patient: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.total_patient_unused_payments = r
        console.log('total unused patient payments')
        console.log(parent.total_patient_unused_payments)
      })
    },

    get_payment_modes() {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_payment_modes',
        args: {
          patient: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        parent.payment_modes = r
      })
    },
    subscibe_payements() {
      console.log('subscribed')
      const parent = this
      const subscription_channel = `${this.patient_number}_payments_listener`
      console.log(`subscibed to ${subscription_channel}`)
      // subscribe to channel
      window.socket.on(subscription_channel, data => {
        // this.show_alert({  message: data.body,indicator: 'green' }, 55);
        parent.response_message = data.body
        if (data.title === 'Payment Made') {
          parent.makeToastVar('info', 'Payment')
        }
      })
    },
    unsubscibe_payements() {
      const subscription_channel = `${this.patient_number}_payments_listener`
      window.socket.off(subscription_channel)
      console.log('unsubscribed')
    },

    verifyMpesaPayment() {
      frappe.require('/assets/frontend/js/mpesa_dialog.js', () => {
        mpesaDialog(this.customerNumber, this.patient_number)
      })
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.response_message, {
        title: 'Billing',
        variant,
        solid: true,
      })
    },
    makeToastVar(variant = null, title = null) {
      this.$bvToast.toast(this.response_message, {
        title,
        variant,
        solid: true,
      })
    },
    ipatient_allocations() {
      const parent = this
      parent.allocating_inpatient_allocations = true
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.cron_jobs.single_patient_inpatient_billing',
        args: {
          patient: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        if ('success' in r) {
          parent.allocating_inpatient_allocations = false
          parent.refresh_payments()
        }
      })
    },
    refresh_payments() {
      this.getPayments()
      this.get_total_payments()
      this.get_total_unsed_payments()
    },

    verifyMpesaApi() {
      this.showDismissibleAlert = false
      this.showDismissibleAlertSuccess = false
      this.showDismissibleAlertError = false

      this.showDismissibleAlert = false
      this.showDismissibleAlertSuccess = false
      this.showDismissibleAlertError = false

      this.checking_mpesa = false
      this.mpesa_verify_button = true

      try {
        const subscription_value = `${this.patient_no}mpesa_verify`
        window.socket.off(subscription_value)
      } catch (err) {
        console.log(err.message)
      }

      // let note = "(NB: This will check for payments from Safaricoms's End)";
      // this.show_alert({ message:note ,indicator: 'blue'}, 2);
      const parent = this

      this.verify_party = `${parent.patientName} (${parent.customerNumber})`
      this.verify_transaction_code = null
      this.verifyMpesaModalVisible = true
      // let dialog = new frappe.ui.Dialog({
      //     title: `Verify Mpesa Payment for ${parent.patientName} `,
      //     fields: [{
      //         label: 'Name',
      //         fieldname: 'party',
      //         fieldtype: 'Read Only',
      //         options: 'Customer',
      //         default: `${parent.patientName} (${parent.customerNumber})`,
      //         readonly: 0
      //     },
      //     {
      //         label: 'Transaction Code',
      //         fieldname: 'transaction_code',
      //         fieldtype: 'Data',
      //         reqd: 1
      //     }
      //     ],
      //     primary_action_label: 'Check Transaction',
      //     primary_action(values) {
      //         // save payment entry
      //         // console.log(JSON.stringify(dialog.get_values()));
      //         if (values.transaction_code) {
      //             //    console.log(patient_no);
      //             //    console.log(values.transaction_code.toUpperCase());
      //             this.show_alert({
      //                 message: 'checking please Wait...',
      //                 indicator: 'green'
      //             }, 2);
      //             parent.checking_mpesa = true;
      //             parent.mpesa_verify_button = false;
      //             api({
      //                 method:
      //                     "billing.billing.api.payment_integration.mpesa_payment_verify.initiate_mpesa_verification",
      //                 args: {
      //                     patient_number: patient_no,
      //                     transaction_code: values.transaction_code.toUpperCase()
      //                 },
      //                 freeze: false,
      //             }).then(r => {
      //                 console.log('initial ver response');
      //                 console.log(r);
      //                 // console.log('dialog state '+ parent.showDismissibleAlert);

      //                 if ("ResponseCode" in r) {
      //                     if (r.ResponseCode && r.ResponseCode === '0') {
      //                         //     this.show_alert({
      //                         //     message: 'please Wait...',
      //                         //     indicator: 'green'
      //                         // }, 3);

      //                         //subscribe to channel
      //                         console.log('SUBCRIBED TO PAYMENTS LISTENER');
      //                         let subscription_value = patient_no + 'mpesa_verify';
      //                         window.socket.on(subscription_value, (data) => {
      //                             // this.show_alert({  message: data.body,indicator: 'green' }, 55);
      //                             parent.response_message = data.body;
      //                             if (data.title === 'No Payment Found') {
      //                                 parent.showDismissibleAlertError = true;
      //                                 parent.makeToast('danger');
      //                             }
      //                             else if (data.title === 'declined') {
      //                                 parent.showDismissibleAlertError = true;
      //                                 parent.makeToast('danger');
      //                             }

      //                             else if (data.title === 'success') {
      //                                 parent.showDismissibleAlertSuccess = true;
      //                                 parent.makeToast('success');
      //                             }

      //                             else {
      //                                 parent.showDismissibleAlert = true;
      //                                 parent.makeToast('info');
      //                             }

      //                             //console.log(data);

      //                             window.socket.off(subscription_value);
      //                             //console.log('Realtime Watcher off '+ patient_no);
      //                             parent.getPayments();

      //                             parent.checking_mpesa = false;
      //                             parent.mpesa_verify_button = true;
      //                         });

      //                     }
      //                     else {
      //                         this.show_alert({
      //                             message: 'Failed, please Try Again',
      //                             indicator: 'red'
      //                         }, 3);

      //                         parent.checking_mpesa = false;
      //                         parent.mpesa_verify_button = true;
      //                     }
      //                 }

      //                 //payment alreadly done
      //                 if ("Duplicate" in r) {

      //                     parent.response_message = r.Duplicate;
      //                     parent.showDismissibleAlert = true;
      //                     parent.makeToast('info');

      //                     parent.checking_mpesa = false;
      //                     parent.mpesa_verify_button = true;

      //                 }

      //                 //process stalled payment
      //                 if ("Process" in r) {
      //                     parent.response_message = r.Process;
      //                     parent.showDismissibleAlertSuccess = true;
      //                     parent.makeToast('success');
      //                     parent.getPayments();

      //                     parent.checking_mpesa = false;
      //                     parent.mpesa_verify_button = true;
      //                 }

      //                 if ("Reverse" in r) {

      //                     parent.response_message = r.Reverse;
      //                     parent.showDismissibleAlert = true;
      //                     parent.makeToast('info');

      //                     parent.checking_mpesa = false;
      //                     parent.mpesa_verify_button = true;

      //                     frappe.confirm(`Reverse Payment ? By Doing so You willTransfer Payment from ${r.Old_Patient}  to ${patient_no} and this action is logged in an audit trail, are you sure you want to proceed?`,
      //                         () => {
      //                             // action to perform if Yes is selected
      //                             this.show_alert({
      //                                 message: 'working please Wait...',
      //                                 indicator: 'green'
      //                             }, 2);
      //                             parent.checking_mpesa = true;
      //                             parent.mpesa_verify_button = false;
      //                             api({
      //                                 method:
      //                                     "billing.billing.api.payment_integration.mpesa_payment_verify.cancel_payment_entry",
      //                                 args: {
      //                                     patient_number: patient_no,
      //                                     transaction_code: values.transaction_code.toUpperCase()
      //                                 },
      //                                 freeze: false,
      //                             }).then(r => {
      //                                 console.log(r);
      //                                 //process stalled payment
      //                                 if ("Process" in r) {
      //                                     parent.response_message = r.Process;
      //                                     parent.showDismissibleAlertSuccess = true;
      //                                     parent.makeToast('success');
      //                                     parent.getPayments();
      //                                     parent.checking_mpesa = false;
      //                                     parent.mpesa_verify_button = true;
      //                                 }

      //                                 if ("Error" in r) {
      //                                     parent.response_message = r.Error;
      //                                     parent.showDismissibleAlertError = true;
      //                                     parent.makeToast('danger');
      //                                     parent.getPayments();
      //                                     parent.checking_mpesa = false;
      //                                     parent.mpesa_verify_button = true;
      //                                 }
      //                             });

      //                             //console.log(r);
      //                             // console.log('dialog state '+ parent.showDismissibleAlert);
      //                         }, () => {
      //                             // action to perform if No is selected
      //                             parent.response_message = 'Action cancelled ';
      //                             parent.makeToast('info');
      //                         })

      //                     parent.getPayments();
      //                 }

      //                 if ("Error" in r) {
      //                     parent.response_message = r.Error;
      //                     parent.showDismissibleAlertError = true;
      //                     parent.makeToast('danger');
      //                     parent.getPayments();

      //                     parent.checking_mpesa = false;
      //                     parent.mpesa_verify_button = true;

      //                 }

      //             });
      //             dialog.hide();
      //         } else {
      //             dialog.hide();
      //             this.show_alert({
      //                 message: `Enter A Valid Transaction Id`,
      //                 indicator: 'red'
      //             }, 5);

      //             parent.checking_mpesa = false;
      //             parent.mpesa_verify_button = true;
      //         }
      //     },
      //     secondary_action_label: 'Cancel',
      //     secondary_action() {
      //         dialog.hide();
      //         let subscription_value = this.patient_no + 'mpesa_verify';
      //         window.socket.off(subscription_value);
      //         //console.log('Realtime Watcher off '+ patient_no);

      //         parent.checking_mpesa = false;
      //         parent.mpesa_verify_button = true;
      //     }
      // });

      // dialog.show();
    },
    cancelVerifyMpesa() {
      // Hide the verify Mpesa modal on cancel
      this.verifyMpesaModalVisible = false
      const subscription_value = `${this.patient_no}mpesa_verify`
      window.socket.off(subscription_value)
      // console.log('Realtime Watcher off '+ patient_no);

      parent.checking_mpesa = false
      parent.mpesa_verify_button = true
    },
    submitVerifyMpesa() {
      const parent = this
      // Perform your submit logic for verifying Mpesa payment here
      if (this.verify_transaction_code) {
        this.show_alert({
          message: 'Checking, please wait...',
          indicator: 'green',
        }, 2)
        parent.checking_mpesa = true
        parent.mpesa_verify_button = false

        const apiData = {
          patient_number: patient_no, // Assuming patient_no is defined in your component
          transaction_code: this.verify_transaction_code.toUpperCase(),
        }

        api({
          method: 'billing.billing.api.payment_integration.mpesa_payment_verify.initiate_mpesa_verification',
          args: apiData,
          freeze: false,
        }).then(r => {
          // Handle the API response
          console.log('initial version response')
          console.log(r)

          // ... handle different cases based on the response ...

          // Subscribe to channel
          const subscription_value = `${patient_no}mpesa_verify`
          window.socket.on(subscription_value, data => {
            window.socket.off(subscription_value)
            parent.getPayments()
            parent.checking_mpesa = false
            parent.mpesa_verify_button = true
          })
        })

        this.verifyMpesaModalVisible = false
      } else {
        // Invalid transaction code
        this.verifyMpesaModalVisible = false
        this.show_alert({
          message: 'Enter a valid Transaction ID',
          indicator: 'red',
        }, 5)

        parent.checking_mpesa = false
        parent.mpesa_verify_button = true
      }
    },
    printReceipt(paymentItem) {
      console.log(paymentItem)
      url_ = `${'/printview?'
                + 'doctype='}${encodeURIComponent('Payment Entry')
      }&name=${encodeURIComponent(paymentItem.Payment)
      }&format=Patient Official Receipt&_lang=en&trigger_print=1`
      console.log(url_)
      const w = window.open(frappe.urllib.get_full_url(url_))
      if (!w) {
        msgprint(('Please enable pop-ups'))
      }
    },

    printParentReceipt(paymentItem) {
      console.log('🔥🔥🔥 type🔥🔥🔥')
      console.log(paymentItem)
      if (paymentItem.Type == 'PAYBILL') {
        var w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                    + 'doctype='}${encodeURIComponent('MPESA Payments')
        }&name=${encodeURIComponent(paymentItem.Reference)
        }&format=Mpesa Billing&_lang=en&trigger_print=1`))
        if (!w) {
          msgprint(('Please enable pop-ups'))
        }
      } else if (paymentItem.Type == 'KCB AGENT') {
        var w = window.open(frappe.urllib.get_full_url(`${'/printview?'
                    + 'doctype='}${encodeURIComponent('KCB Payments')
        }&name=${encodeURIComponent(paymentItem.Name)
        }&format=mtrh kcb&_lang=en&trigger_print=1`))
        if (!w) {
          msgprint(('Please enable pop-ups'))
        }
      } else {
        console.log(paymentItem)
        url_ = `${'/printview?'
                    + 'doctype='}${encodeURIComponent('Payment Entry')
        }&name=${encodeURIComponent(paymentItem.Name)
        }&format=Patient Official Receipt&_lang=en&trigger_print=1`
        console.log(url_)
        var w = window.open(frappe.urllib.get_full_url(url_))
        if (!w) {
          msgprint(('Please enable pop-ups'))
        }
      }
    },
    createPaymentEntry() {
      const parent = this
      const dialog = new frappe.ui.Dialog({
        title: `Add Payment Entry for ${parent.patientName}`,
        fields: [
          {
            label: 'Table Container',
            fieldname: 'table_container',
            fieldtype: 'HTML',
            default: '',
            readonly: 0,
          },
          {
            label: 'Client Name',
            fieldname: 'party',
            fieldtype: 'Read Only',
            options: 'Customer',
            default: `${parent.patientName} (${parent.customerNumber})`,
            readonly: 0,
          },
          // {
          //     label: 'Sales Order',
          //     fieldname: 'sales_order',
          //     fieldtype: 'Link',
          //     options: 'Sales Order',
          //     // hidden: doctype,
          //     filters: { customer: parent.customerNumber, status: ['not in', ['Completed', 'Cancelled']] },
          // },
          {
            label: 'Mode of Payment',
            fieldname: 'mode_of_payment',
            fieldtype: 'Link',
            options: 'Mode of Payment',
            // filters: { account_type: ['in', ['Cash', 'Bank']] },
            reqd: 1,
          },
          {
            label: 'Reference',
            fieldname: 'reference_no',
            fieldtype: 'Data',
            reqd: 1,
            // default: Math.floor(Date.now()).toString(36).toUpperCase().toString()
          },
          {
            label: 'Amount',
            fieldname: 'paid_amount',
            fieldtype: 'Currency',
            reqd: 1,
          },
          {
            label: 'Confirm Amount',
            fieldname: 'confirm_amount',
            fieldtype: 'Currency',
            reqd: 1,
            change() {
              // Update "Amount Tendered" when "Confirm Amount" changes
              const confirmAmount = this.get_value()
              dialog.set_value('tendered_amount', confirmAmount)
            },
            onfocusout() {
              // Additional event (onfocusout) to update "Amount Tendered"
              const confirmAmount = this.get_value()
              dialog.set_value('tendered_amount', confirmAmount)
            },
          },
          {
            label: 'Amount Tendered',
            fieldname: 'tendered_amount',
            fieldtype: 'Currency',
            readonly: 1,
            disabled: 1,
            // reqd: 1
          },
          // {
          //     label: 'Change',
          //     fieldname: 'change_amount',
          //     fieldtype: 'Read Only',
          //     default: 0,
          //     readony: 0,
          // },
          // {
          //     label: 'Change Account',
          //     fieldname: 'account_for_change',
          //     fieldtype: 'Link',
          //     options: 'Account',
          //     // hidden: doctype,
          //     filters: { account_type: "Cash" },
          // },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          // save payment entry
          // console.log(JSON.stringify(dialog.get_values()));
          if (values.sales_order) {
            // && values.confirm_amount === values.paid_amount
            const diff = values.paid_amount - values.confirm_amount
            parseFloat(values.confirm_amount) !== parseFloat(values.paid_amount) ? frappe.throw(`Sorry the values you entered differ with invoice by ${diff}`)
              : savePaymentEntry({
                docname: values.sales_order,
                doctype: 'Sales Order',
                amount: values.paid_amount,
                // account_for_change:values.account_for_change,
                change_amount: values.change_amount,
                amount_tendered: values.tendered_amount,
                reference_no: values.reference_no,
                // paid_to: values.paid_to
                mode_of_payment: values.mode_of_payment,
              }).then(r => {
                parent.$emit('newPaymentEntry')
                this.show_alert({
                  message: 'Payment Entry Created',
                  indicator: 'green',
                }, 5)
                // dialog.hide();
                parent.getPayments()
              })
            dialog.hide()
          } else {
            dialog.hide()
            savePrePayment({
              customer_name: parent.customerNumber,
              patient_name: parent.patient_number,
              amount: values.paid_amount,
              // account_for_change:values.account_for_change,
              change_amount: values.change_amount,
              amount_tendered: values.tendered_amount,
              reference_no: values.reference_no,
              // paid_to: values.paid_to
              mode_of_payment: values.mode_of_payment,
            }).then(r => {
              parent.$emit('newPaymentEntry')
              this.show_alert({
                message: 'Payment Entry Created',
                indicator: 'green',
              }, 5)
              parent.getPayments()
            })
          }
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dialog.hide()
        },
      })
      dialog.fields_dict.tendered_amount.df.onchange = () => {
        // dialog.get_primary_btn().attr("disabled", dialog.get_value("paid_amount") !== dialog.get_value("confirm_amount"));
        //  dialog.get_value("confirm_amount")
        if (dialog.get_value('paid_amount') === dialog.get_value('confirm_amount')) {
          if (dialog.get_value('tendered_amount') >= dialog.get_value('paid_amount')) {
            dialog.set_values({ change_amount: dialog.get_value('tendered_amount') - dialog.get_value('paid_amount') })
          } else {
            const diff = dialog.get_value('tendered_amount') - dialog.get_value('paid_amount')
            dialog.get_primary_btn().attr('disabled', false)
            this.msgprint(`Sorry the tendered amount is less than paid amount by ${diff.toFixed(2)}`)
          }
        } else {
          dialog.get_primary_btn().attr('disabled', false)
        }
      }
      dialog.get_primary_btn().attr('disabled', true)
      dialog.fields_dict.paid_amount.df.onchange = () => {
        dialog.get_primary_btn().attr('disabled', dialog.get_value('paid_amount') !== dialog.get_value('confirm_amount'))
      }
      dialog.fields_dict.confirm_amount.df.onchange = () => {
        dialog.get_primary_btn().attr('disabled', dialog.get_value('paid_amount') !== dialog.get_value('confirm_amount'))
      }
      dialog.show()
      const pendingChargeSheets = parent.generateChargeSheetTable()
      dialog.fields_dict.table_container.$wrapper.html(pendingChargeSheets)
    },

    do_nothing(messege) {
      const parent = this
      parent.response_message = messege
      parent.makeToast('warning')
      console.log('doing nothing :-)')
    },
    open_payments_new_tab(doctype, doc_name, label) {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_link_to_form_new_tab',
        args: {
          doctype,
          name: doc_name,
          label,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        const url = r
        window.open(url, '_blank')
      })
    },
    getPayments() {
      const parent = this
      parent.isBusy = true
      parent.isBusy_2 = true
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_patient_payments_mpesa_kcb',
        args: {
          patient_number: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        // process stalled payment
        this.MpesaKcbPayments = r
        console.log('kcb mpesa')
        console.log(this.MpesaKcbPayments)
        parent.isBusy_2 = false
      })
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_patient_payments',
        args: {
          patient_number: this.patient_number,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        // process stalled payment
        this.PaymentEntries = r
        console.log('here')
        console.log(this.PaymentEntries)
        parent.isBusy = false
      })
    },
    generateChargeSheetTable() {
      const chargeSheets = [

      ]

      let tableHTML = `
            <h5 class="mb-0">Recent Pending Appointments Charges/Charge Sheets</h5>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Warehouse</th>
                    <th>Amount</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
            `

      chargeSheets.forEach(item => {
        tableHTML += `
                <tr>
                    <td>
                    <button class="btn btn-outline-primary">${item.name}</button>
                    </td>
                    <td>${item.warehouse}</td>
                    <td>
                    <h4><span class="badge badge-warning">${item.amount}</span></h4>
                    </td>
                </tr>
                `
      })

      tableHTML += `
                </tbody>
            </table>
            `

      return tableHTML
    },

  },
}
</script>
