<template>
  <div>

    <b-container>
      <b-row>
        <div>
          <!-- <h5><b>{{passed_charge_sheet_Name}} </b> </h5> -->
          <h5><b>Billed as:  </b> <b>{{ charge_sheet_billed_as }}</b> </h5>
          <h5><b>Warehouse:  </b> {{ charge_sheet_warehouse }} </h5>
          <br>

          <!-- <h5><b>Billed Amount:    </b> <strong>{{ charge_sheet_total }}</strong></h5>
                <h5><b>Paid Amount:    </b> <strong>{{ charge_sheet_total_paid }}</strong></h5>
                <h5><b>Balance:    </b> <strong>{{ charge_sheet_balance }}</strong></h5> -->

          <br>
          <h5><b>Billed  By:  </b> {{ charge_sheet_owner }} </h5>
          <h5><b>Billed On:  </b> {{ formatDate (charge_sheet_creation) }} </h5>

          <h5 style="margin-top: 8px;">
            <b> Amounts:  </b>
          </h5>
          <b-button-group style="margin-top: 5px;">
            <b-button
              v-b-tooltip.hover
              title="Total amount paid from 23 March 2023"
              :pressed="true"
              variant="dark"
            >
              Billed Amount: <strong>  {{ charge_sheet_total }}</strong>
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Total amount available for dispensing services"
              :pressed="true"
              variant="light"
            >
              Paid Amount:<strong>  {{ charge_sheet_total_paid }} </strong>
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Total amount available for dispensing services"
              :pressed="true"
              variant="light"
            >
              Balance:<strong>  {{ charge_sheet_balance }} </strong>
            </b-button>
          </b-button-group>

        </div>

      </b-row>
      <b-row>
        <b-col />
        <b-button
          v-if="hidden_stats"
          size="sm"
          class="ml-2"
          variant="primary"
          @click="selectAllRows"
        >
          Select all
        </b-button>
        <b-button
          v-if="hidden_stats"
          size="sm"
          class="mx-2"
          variant="primary"
          @click="clearSelected"
        >
          Clear selected
        </b-button>
        <b-button
          v-if="charge_sheet_selected_items.length || hidden_stats"
          size="sm"
          class="mx-2"
          variant="success"
          @click="migrate"
        >Migrate
          Items</b-button>
        <b-button
          v-if="charge_sheet_selected_items.length || hidden_stats"
          size="sm"
          variant="danger"
          @click="postAdjustment"
        >
          Post adjustment
        </b-button>
      </b-row>
      <div />
      <b-row style="margin-top: 10px;">

        <div><h5> Billed Items:</h5></div>

        <b-table
          ref="selectableTable"
          small
          style="width: 100%"
          responsive="sm"
          bordered
          striped
          :items="charge_sheets_items"
          :empty-text="`No items `"
          :fields="fields"
          :empty-filtered-text="`No items`"
          head-row-variant="primary"
          :show-empty="true"
          selected-variant="info"
          :select-mode="'multi'"
          selectable
          @row-selected="onRowSelected"
        >

          <template #cell(Quantity)="row">
            <div class="text-center">
              <div
                v-if="(charge_sheet_selected_items.find(element => element == row.item))"
                class="text-center"
              >
                <b-form-input
                  v-model="row.item.Final_Quantity"
                  type="number"
                  style="margin-left:0px; align-self: center; justify-self: center;  width:100%; max-width: 100px;"
                  @change="changeQuantity(row.item)"
                />
              </div>
              <div v-else><strong v-if=" row.item.Final_Quantity === row.item.Actual_Quantity">{{ row.item.Actual_Quantity }}</strong>
                <strong
                  v-else
                  style="color: blue;"
                >{{ row.item.Final_Quantity }}</strong>
              </div>
            </div>
          </template>

          <template #cell(Item_Name)="row">
            <strong>{{ row.item.Item_Name }}</strong>
          </template>

          <template #cell(Selling_Rate)="row">
            <div class="text-center">
              <strong>{{ formatPrice(row.item.Selling_Rate) }}</strong>
            </div>
          </template>

          <template #cell(Amount)="row">
            <div class="text-center">
              <strong>KSH {{ formatPrice(row.item.Amount) }}</strong>
            </div>
          </template>

          <template #cell(Actions)="row">
            <div class="text-center">
              <b-button
                v-if="row.item.Actual_Quantity !== 0 "
                size="sm"
                :disabled="row.item.Is_Returning"
                variant="primary"
                @click="returnAll(row.item)"
              >
                Return all
              </b-button>
            </div>
          </template>
        </b-table>

      </b-row>

      <b-row v-if="returns_available">
        <div>  <h5
          class="my-2"
          style="color: red;"
        >
          Returns:
        </h5></div>

        <b-table
          ref="selectableTable2"
          small
          style="width: 100%"
          responsive="sm"
          bordered
          striped
          :items="charge_sheets_items_returns"
          :empty-text="`No items `"
          :fields="fields"
          :empty-filtered-text="`No items`"
          head-row-variant="danger"
          :show-empty="true"
          selected-variant="warning"
          :select-mode="'multi'"
          selectable
        >
          <template #cell(Item_Name)="row">
            <strong style="color: red;">{{ row.item.Item_Name }} </strong>
          </template>

          <template #cell(Quantity)="row">
            <div class="text-center">
              <strong
                style="color: red;"
                class="text-center"
              >{{ row.item.Quantity }}</strong>
            </div>
          </template>

          <!-- <template #cell(Actions)="row">
                    <div class="text-center" v-if="hidden_stats" >
                        <b-button size="sm" variant="danger" @click="cancelReturn(row.item)"> Cancel Return</b-button>
                    </div>
                </template> -->

          <template #cell(Selling_Rate)="row">
            <div class="text-center">
              <strong style="color: red;">{{ formatPrice(row.item.Selling_Rate) }}</strong>
            </div>
          </template>

          <template #cell(Amount)="row">
            <div class="text-center">
              <strong style="color: red;">KSH {{ formatPrice(row.item.Amount) }}</strong>
            </div>
          </template>
        </b-table>
      </b-row>

    </b-container>

  </div>
</template>

<script>
import moment from 'moment'
import {
  api,
} from './service'

export default {
  components: {

  },
  props: {
    passed_charge_sheet_Name: {
      type: String,
      required: true,
    },
    passed_owner:
        {
          type: String,
          required: true,
        },
  },
  data() {
    return {
      charge_sheets_items: [],
      charge_sheets_items_returns: [],
      charge_sheet_selected_items: [],

      fields: ['Id', 'Item_Name', 'Quantity', 'Selling_Rate', 'Amount'],

      hidden_stats: false,

      charge_sheet_owner: '',
      charge_sheet_total: 0,
      charge_sheet_total_paid: 0,
      charge_sheet_balance: 0,
      charge_sheet_warehouse: '',
      charge_sheet_creation: '',
      charge_sheet_billed_as: '',
    }
  },
  computed: {
    message() {
      return this.counter > 0 ? 'Counter is positive' : 'Counter is non-positive'
    },
  },
  created() {
    console.log('Component created')
  },
  mounted() {
    console.log('Component mounted')
    this.get_items()
    this.get_charge_sheet_details()
  },
  updated() {
    console.log('Component updated')
  },
  destroyed() {
    console.log('Component destroyed')
  },
  methods: {
    formatDate(date) {
      return moment(date).format('Do MMM YYYY ,  h:mm:ss a')
    },
    formatPrice(value) {
      value = Math.ceil(value)
      value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return value
    },
    makeVarToast(variant = null, title = null) {
      this.$bvToast.toast(this.response_message, {
        title,
        variant,
        solid: true,
      })
    },
    get_items() {
      const parent = this
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.charge_sheet_items.get_charge_sheets_items_frontend',
        args: {
          parent: parent.passed_charge_sheet_Name,
        },
        freeze: false,
      }).then(r => {
        console.log('new response')
        console.log(r)
        parent.charge_sheets_items = r.Items
        parent.charge_sheets_items_returns = r.Returns
        if (parent.charge_sheets_items_returns.length > 0) {
          parent.returns_available = true
        }
        parent.set_charge_sheet_items()
      })
    },

    get_charge_sheet_details() {
      const parent = this
      api({
        method:
                    'billing.billing.api.payment_integration.Payments_frontend.get_charge_sheet_details',
        args: {
          charge_sheet_name: parent.passed_charge_sheet_Name,
        },
        freeze: false,
      }).then(r => {
        console.log(r)
        if ('success' in r) {
          console.log('new response')
          parent.charge_sheet_owner = r.success.Charge_Sheet_Owner
          parent.charge_sheet_total = r.success.Charge_Sheet_Total
          parent.charge_sheet_total_paid = r.success.total_paid
          parent.charge_sheet_balance = r.success.balance
          parent.charge_sheet_billed_as = r.success.Payment_Type
          parent.charge_sheet_warehouse = r.success.Charge_Sheet_Warehouse
          parent.charge_sheet_creation = r.success.Charge_Sheet_Date
        }
      })
    },
    set_charge_sheet_items() {
      const parent = this
      const items_charge_sheet = parent.charge_sheets_items
      parent.charge_sheets_items = []
      items_charge_sheet.forEach((item, index) => {
        parent.charge_sheets_items.push(
          {
            Id: item.Id,
            Name: item.Name,
            Item_Name: item.Item_Name,
            Item_Code: item.Item_Code,
            Quantity: item.Quantity,
            Actual_Quantity: item.Actual_Quantity,
            Final_Quantity: item.Final_Quantity,
            Selling_Rate: item.Selling_Rate,
            Amount: item.Amount,
            Is_Returning: false,
          },
        )
      })
    },
  },
}
</script>

<style scoped>
/* Component-specific styles go here */
</style>
