import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const savePaymentEntry = ({
  docname,
  doctype,
  amount,
  account_for_change,
  change_amount = 0,
  amount_tendered,
  reference_no,
  mode_of_payment,
}) => api({
  method: 'billing.billing.api.accounts.patients.create_payment_entry',
  args: {
    docname,
    doctype,
    amount,
    account_for_change,
    change_amount,
    amount_tendered,
    reference_no,
    mode_of_payment,
  },
})

export const savePrePayment = ({
  customer_name,
  amount,
  account_for_change,
  change_amount = 0,
  amount_tendered,
  patient_name,
  reference_no,
  mode_of_payment,
}) => api({
  method: 'billing.billing.api.accounts.patients.create_prepayment',
  args: {
    customer_name,
    amount,
    account_for_change,
    change_amount,
    amount_tendered,
    reference_no,
    patient_name,
    mode_of_payment,
  },
})
