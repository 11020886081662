<template>
  <b-container>
    <b-row> <strong>{{ parent }}</strong>  -  <span> Billed by: </span> <b> {{ owner_display }}  <template>- (+254) {{ owner_phone }}</template> </b>
      <b-col />
      <b-button
        v-if="!selectedAllItems"
        size="sm"
        style="margin-right: 3px;"
        class="ml-2"
        variant="primary"
        @click="selectAllRows"
      >
        Select all
      </b-button>
      <b-button
        v-if="selectedSomeItems"
        size="sm"
        style="margin-right: 3px;"
        class="mx-2"
        variant="primary"
        @click="clearSelected"
      >
        Clear selected
      </b-button>

      <b-button
        v-if="charge_sheet_selected_items.length"
        size="sm"
        variant="danger"
        @click="postAdjustment"
      >
        Post adjustment
      </b-button>
    </b-row>
    <div />
    <b-row v-if="charge_sheets_items.length > 0">

      <div><h5> Billed Items:</h5></div>

      <b-table
        ref="selectableTable"
        class="custom-table"
        small
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="charge_sheets_items"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="secondary"
        :show-empty="true"
        selected-variant="warning"
        :select-mode="'multi'"
        selectable
        @row-selected="onRowSelected"
      >

        <template #top-row="{ fields }">
          <!-- Check if at least one item is selected to show the checkbox column -->
          <b-table-col-checkbox v-if="selectedSomeItems" />
          <b-table-col
            v-for="field in fields"
            :key="field.key"
            :label="field.key === '#' ? '' : field.label"
            :class="field.key === '#' ? 'no-padding-no-margin header-bg' : ''"
          />
        </template>
        <template
          v-if="selectedSomeItems"
          #cell(#)="row"
          style="margin: 0; border: none; padding:0px;"
        >
          <b-form-checkbox
            v-if="selectedSomeItems"
            v-model="row.item.selected"
          />
        </template>

        <template #cell(Quantity)="row">
          <div class="text-center">
            <div
              v-if="(charge_sheet_selected_items.find(element => element == row.item))"
              class="text-center"
            >
              <b-form-input
                v-model="row.item.Final_Quantity"
                type="number"
                style="margin-left:0px; align-self: center; justify-self: center;  width:100%; max-width: 100px;"
                @change="changeQuantity(row.item)"
              />
            </div>
            <div v-else><strong v-if=" row.item.Final_Quantity === row.item.Actual_Quantity">{{ row.item.Actual_Quantity }}</strong>
              <strong
                v-else
                style="color: blue;"
              >{{ row.item.Final_Quantity }}</strong>
            </div>
          </div>
        </template>

        <template #cell(Item_Name)="row">
          <strong>{{ row.item.Item_Name }}</strong>
        </template>

        <template #cell(Selling_Rate)="row">
          <div class="text-center">
            <strong>{{ formatPrice(row.item.Selling_Rate) }}</strong>
          </div>
        </template>

        <template #cell(Amount)="row">
          <div class="text-center">
            <strong>KSH {{ formatPrice(row.item.Amount) }}</strong>
          </div>
        </template>

        <template #cell(Actions)="row">
          <div class="text-center">
            <b-button
              v-if="row.item.Actual_Quantity !== 0 && doc_status == 1 && !row.item.is_returning_all "
              size="sm"
              :disabled="row.item.is_returning_all"
              variant="primary"
              @click="returnAll(row.item)"
            >
              {{ row.item.is_returning_all ? 'Return all':'Return all' }}
            </b-button>
            <b-spinner
              v-if="row.item.is_returning_all"
              variant="success"
              label="Returning Spinner"
            />
          </div>
        </template>
      </b-table>
    </b-row>

    <b-row v-if="charge_sheets_items_returns.length > 0">
      <div>  <h5
        class="my-2"
        style="color: red;"
      >
        Returns:
      </h5></div>

      <b-table
        ref="selectableTable2"
        small
        style="width: 100%"
        responsive="sm"
        bordered
        striped
        :items="charge_sheets_items_returns"
        :empty-text="`No items `"
        :fields="fields"
        :empty-filtered-text="`No items`"
        head-row-variant="danger"
        :show-empty="true"
        selected-variant="warning"
        :select-mode="'multi'"
        selectable
      >
        <template #cell(Item_Name)="row">
          <strong style="color: red;">{{ row.item.Item_Name }} </strong>
        </template>

        <template #cell(Quantity)="row">
          <div class="text-center">
            <strong
              style="color: red;"
              class="text-center"
            >{{ row.item.Quantity }}</strong>
          </div>
        </template>

        <template #cell(Actions)="row">
          <div class="text-center">
            <b-button
              v-if="!row.item.is_cancelling_return"
              size="sm"
              variant="danger"
              :disabled="row.item.is_cancelling_return"
              @click="cancelReturn(row.item)"
            >
              {{ row.item.is_cancelling_return ? 'Cancel Return':'Cancel Return' }}
            </b-button>
            <b-spinner
              v-if="row.item.is_cancelling_return"
              variant="danger"
              label="Cancelling Spinner"
            />
          </div>
        </template>

        <template #cell(Selling_Rate)="row">
          <div class="text-center">
            <strong style="color: red;">{{ formatPrice(row.item.Selling_Rate) }}</strong>
          </div>
        </template>

        <template #cell(Amount)="row">
          <div class="text-center">
            <strong style="color: red;">KSH {{ formatPrice(row.item.Amount) }}</strong>
          </div>
        </template>
      </b-table>
    </b-row>

  </b-container>
</template>
<script>
import {
  chargeSheetAddItemReturn,
  chargeSheetMigrate,
  chargeSheetCancelReturn,
  api,
} from './service'
import ItemName from './Item.vue'

export default {
  components: {
    ItemName,
  },
  props: {
    parent: String,
    owner: String,
    doc_status: Number,
    owner_display: String,
    owner_phone: String,
    is_standing_charge: Number,
  },
  data() {
    return {
      charge_sheets_items: [],
      charge_sheets_items_returns: [],
      result: null,
      warehouse: null,
      fields: ['#', 'Id', 'Item_Name', 'Quantity', 'Selling_Rate', 'Amount', 'Actions'],
      charge_sheet_selected_items: [],

      return_form_state: true,
      returns_available: false,
      new_quantity: 0,
      accordion_toggled: true,
      response_message: '',
    }
  },
  computed: {
    selectedAllItems() {
      // Filter the array to get only the items with 'selected' field set to false
      const unselectedItems = this.charge_sheets_items.filter(item => !item.selected)
      // Return true if there are no unselected items
      return unselectedItems.length === 0
    },
    selectedSomeItems() {
      // Filter the array to get only the items with 'selected' field set to true
      const selectedItems = this.charge_sheets_items.filter(item => item.selected)
      // Return true if there are some selected items
      return selectedItems.length > 0
    },

  },
  mounted() {
    // this.load();
    this.get_items()
  },
  methods: {
    onRowSelected(items) {
      console.log('row selected items')
      console.log(items)
      this.charge_sheet_selected_items = items

      const parent = this
      for (const item of parent.charge_sheets_items) {
        item.selected = false
      }

      for (const item of items) {
        item.selected = true
      }
      console.log(parent.charge_sheets_items)
    },
    formatPrice(value) {
      value = Math.ceil(value)
      value = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return value
    },
    makeVarToast(variant = null, title = null) {
      this.$bvToast.toast(this.response_message, {
        title,
        variant,
        solid: true,
      })
    },
    changeQuantity(item) {
      console.log(`quantity changed to ${item.Final_Quantity}`)
      const old_quantity = item.Actual_Quantity
      const new_quantity = item.Final_Quantity
      if (new_quantity <= 0) {
        item.Final_Quantity = 0
      }

      if (new_quantity > old_quantity) {
        item.Final_Quantity = item.Actual_Quantity
      }
    },
    migrate() {
      const payload = {}
      payload.source_charge_sheet = this.parent
    },
    get_items() {
      const parent = this
      const parent_charge_sheet = this.parent
      console.log(`perent is  ${parent_charge_sheet}`)
      api({
        method:
                    'billing.billing.api.charge_sheet.actions.charge_sheet_items.get_charge_sheets_items_frontend',
        args: {
          parent: parent_charge_sheet,
        },
        freeze: false,
      }).then(r => {
        console.log('new response')
        console.log(r)
        parent.charge_sheets_items = r.Items
        parent.charge_sheets_items_returns = r.Returns
        if (parent.charge_sheets_items_returns.length > 0) {
          parent.returns_available = true
        }
        parent.set_items()
      })
    },
    set_items() {
      const parent = this
      parent.charge_sheets_items = parent.charge_sheets_items.map(item => ({
        ...item,
        selected: false,
        is_returning_all: false,
        is_post_adjusting: false,
      }))

      parent.charge_sheets_items_returns = parent.charge_sheets_items_returns.map(item => ({
        ...item,
        selected: false,
        is_cancelling_return: false,
      }))
    },

    postAdjustment() {
      if (this.getUserSession().user !== this.owner && this.is_standing_charge === 0 && (this.getUserSession().user !== 'Administrator' && this.getUserSession().user != 'dmf@mtrh.go.ke' && !frappe.user_roles.includes('Support Team Master'))) {
        this.$bvToast.toast(' You are not the owner of this document. Only the creator can make returns', {
          title: 'Owner Restriction Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }
      const main_parent = this
      const chargesheet = this.parent
      const results = this.charge_sheet_selected_items.filter(element => element.Final_Quantity !== element.Actual_Quantity)

      console.log(`Return items ${results}`)
      console.log(results)

      if (results == 0 || !results.length) {
        this.$bvToast.toast('No quantities adjustments have been made.', {
          title: 'No Change',
          variant: 'warning',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        this.clearSelected()
        return
      }

      let err = results.find(element => element.Final_Quantity > element.Actual_Quantity)
      if (err) {
        this.$bvToast.toast(`Item  ${err.Item_Name} quantity cannot be adjusted to a value greater than initial one.`, {
          title: 'Quantity Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }

      err = results.find(element => element.qtyx < 0)
      if (err) {
        this.$bvToast.toast(`Item ${err.Item_Name} Cannot have a negative quantity.`, {
          title: 'Quantity Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }

      this.$bvModal.msgBoxConfirm('This action will return the items whose quantities were adjusted. Continue?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Continue',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          console.log('here')
          if (value) {
            console.log('accept there')

            console.log(results)

            api({
              method:
                                'billing.billing.api.charge_sheet.actions.charge_sheet_returns.add_to_charge_sheet_return',
              args: {
                payload: results,
                charge_sheet_id: chargesheet,
              },
              freeze: false,
            }).then(r => {
              console.log('new response')
              console.log(r)

              if ('Success' in r) {
                main_parent.response_message = r.Success
                main_parent.makeVarToast('success', 'Return Successful')
                this.clearSelected()
                this.get_items()
              }
            })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    getIndex(id) {
      let currentIndex = null
      this.charge_sheet_selected_items.forEach((element, index) => {
        if (element.Id == id) {
          currentIndex = index
        }
      })
      return currentIndex
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    returnAll(charge_sheet_item) {
      if (this.getUserSession().user !== this.owner && this.is_standing_charge === 0 && (this.getUserSession().user !== 'Administrator' && this.getUserSession().user != 'dmf@mtrh.go.ke' && !frappe.user_roles.includes('Support Team Master'))) {
        this.$bvToast.toast(' You are not the owner of this document. Only the creator can make returns', {
          title: 'Owner Restriction Error',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }
      charge_sheet_item.is_returning_all = true

      const main_parent = this
      const chargesheet = this.parent
      this.$bvModal
        .msgBoxConfirm('By doing this all items will be returned ,Please confirm if you want to return all items', {
          title: 'Confirm Return',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'proceed',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            charge_sheet_item.Final_Quantity = 0
            const payload = [charge_sheet_item]
            // console.log('return all items');
            // console.log(charge_sheet_item);
            api({
              method:
                                        'billing.billing.api.charge_sheet.actions.charge_sheet_returns.add_to_charge_sheet_return',
              args: {
                payload,
                charge_sheet_id: chargesheet,
              },
              freeze: false,
            }).then(r => {
              console.log('new response')
              console.log(r)

              if ('Success' in r) {
                main_parent.response_message = r.Success
                main_parent.makeVarToast('success', 'Return Successful')
                this.clearSelected()
                this.get_items()
                charge_sheet_item.is_returning_all = false
              }

              charge_sheet_item.is_returning_all = false
            })
          } else {
            charge_sheet_item.is_returning_all = false
          }
        })
    },
    cancelReturn(chargeSheetItem) {
      if (this.getUserSession().user !== this.owner && this.is_standing_charge === 0 && this.getUserSession().user !== 'Administrator' && this.getUserSession().user != 'dmf@mtrh.go.ke') {
        this.$bvToast.toast(' You are not the owner of this document. Only the creator can cancel returns', {
          title: 'Owner Restriction',
          variant: 'danger',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        return
      }
      chargeSheetItem.is_cancelling_return = true

      const main_parent = this
      const chargesheet = this.parent
      this.$bvModal
        .msgBoxConfirm('Please confirm  you want to cancel this return', {
          title: 'Cancel Return',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes, proceed',
          cancelTitle: 'Close',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            const data = chargeSheetItem

            api({
              method:
                            'billing.billing.api.charge_sheet.actions.charge_sheet_returns.cancel_return',
              args: {
                payload: data,
                charge_sheet_id: chargesheet,
              },
              freeze: false,
            }).then(r => {
              console.log('new response')
              console.log(r)

              if ('Success' in r) {
                this.clearSelected()
                this.get_items()
                main_parent.response_message = r.Success
                main_parent.makeVarToast('success', 'Returns Canceled')
                chargeSheetItem.is_cancelling_return = false
              }

              if ('Failed' in r) {
                this.clearSelected()
                this.get_items()
                main_parent.response_message = r.Failed
                main_parent.makeVarToast('danger', 'Returns Failed')
                chargeSheetItem.is_cancelling_return = false
              }
            })
          } else {
            chargeSheetItem.is_cancelling_return = false
          }
        })
    },

    hitReturnApi(items) {
      const payload = {}
      payload.charge_sheet = this.parent
      payload.items = items

      chargeSheetAddItemReturn(payload).then(response => {
        this.$bvToast.toast('Well done. Your adjustments have been accepted back in stock.', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          append: true,
          solid: true,
        })
        this.clearSelected()
        this.load()
      })
    },
  },

}
</script>

<style>
.selected-checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.custom-table thead th:first-child,
.custom-table tbody td:first-child {
  /* Apply styling to the first column (index 1) to remove the border */
  border: none;
  background: white;
  pointer-events: none;
  user-select: none;
  color: white;
  padding: 0;
  display: none;
}

.custom-table .custom-control-input:focus {
  box-shadow: none;
}

.no-padding-no-margin {
  padding: 0 !important;
  margin: 0 !important;
}

.first-column-cell {
  /* Style for the first column cells */
  padding: 0.5rem 1rem;
  margin: 0;
  border: none;
}

</style>
